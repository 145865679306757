import { useLocale } from "../../common/locale/LocaleProvider";

export default function TermsConditions() {

  const { locale } = useLocale()
  const { strings } = locale;

  return (
    <div className="tc-content">
      <div className="tc-note text-center" >
        <ol className="text-start">
          {strings.LUCKY_DRAW_TERMS_CONDITIONS_PAGE_CONTENT.map((item, index) => {
            return <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
          })}
        </ol>
      </div>
    </div>
  )
}