import { useEffect, useState } from "react"
import { useUserContext } from "./UserProvider"

export function UserValidator(props: { children: React.ReactElement<any, string | React.JSXElementConstructor<any>>, disabled?: Boolean }) {
  const ctx = useUserContext()

  const [hasUser, setHasUser] = useState(false)
  
  useEffect(()=> {
    const userId = sessionStorage.getItem('userId')
    if (userId) {
      ctx.dispatch({type: 'NEW_USER', payload:{ userId }})
    }
  }, [])

  useEffect(()=>{
    setHasUser(ctx.user !== undefined)
  }, [ctx.user])

  if (props.disabled) {
    return (props.children)
  }

  return (<>{ hasUser && props.children }</>)
}