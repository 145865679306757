import { useNavigate } from 'react-router-dom';
import { useLanguageFromParam } from '../common/locale/useLanguageFromParam';
import { useEffect } from 'react';
import { useUserFromParam } from '../common/user/UserProvider';
import { useUserFromSharingInfo } from '../common/share/Share';

export default function Root() {

  useLanguageFromParam()
  const navigate = useNavigate()
  const userFromParam = useUserFromParam()
  const userFromSharingInfo = useUserFromSharingInfo()
  const success = userFromParam || userFromSharingInfo
  
  useEffect(()=> {
    if (success) {
      navigate('/landing', {replace:true})
    } else {
      navigate('/', {replace:true})
    }
  }, [success, navigate])

  return (<></>)
}