import { ScanConditions } from 'react-vital-sign-camera';
import checkmark from "./assets/checkmark.svg";
import crossmark from "./assets/crossmark.svg";
import { useLocale } from '../../common/locale/LocaleProvider';
import "bootstrap/dist/css/bootstrap.min.css";
import "./ConditionChecklist.css";
import faceIcon from "./assets/face-id.svg";
import { useEffect, useState } from 'react';
import { isAndroid } from "mobile-device-detect";

const Condition = (props: { condition: string, status: boolean }) => {
  return (
    <div className="condition-container mx-3 d-flex align-items-center">
      {props.status ?
        <img src={checkmark} alt="checkmark" className="icon" />
        :
        <img src={crossmark} alt="crossmark" className="icon" />
      }
      <span className="condition ml-2"> {props.condition}</span>
    </div>
  )
}

const ConditionChecklist = (props: { conditions: ScanConditions, onCountDownFinished:()=>void }) => {
  const { locale } = useLocale();
  const { strings } = locale;
  const [allConditionsMet, setAllConditionsMet] = useState(false)
  const [countDown, setCountDown] = useState(-1)
  const [timer, setTimer] = useState<NodeJS.Timeout|undefined>(undefined)
  const { conditions, onCountDownFinished } = props;

  useEffect(()=>{
    setAllConditionsMet(
      conditions.centered &&
      conditions.distance &&
      (conditions.frameRate || isAndroid) &&
      conditions.lighting &&
      conditions.movement &&
      conditions.serverReady
    );
  }, [conditions])

  useEffect(()=>{

    /* If all conditions are met, start the count down. */
    if (allConditionsMet && !timer) {
      setCountDown(3)
      setTimer( setInterval( () => setCountDown( current => current - 1 ), 1000) )
      console.log("Conditions met, count down started.")
    }

    /* If one of conditions is not met, cancel the count down. */
    if (!allConditionsMet && timer) {
      clearInterval(timer)
      setTimer(undefined)
      setCountDown(-1)
      console.log("Conditions unmet, count down canceled.")
    }

  }, [allConditionsMet, timer])

  useEffect(()=> {

    if (!timer || countDown < 0) {
      return
    }

    if (countDown === 0) {
      clearInterval(timer)
      setTimer(undefined)
      setCountDown(-1)
      onCountDownFinished()
    }

  }, [countDown, onCountDownFinished, timer])

  return (
    <div className="checklist-container position-absolute d-flex justify-content-center align-items-center">
      <div className="condition-checklist-wrapper row flex-row w-100 m-0">
        <div className="condition-checklist-content">
          <div className="row">
            <div className="header align-items-center text-center d=flex flex-row m-0">
              <img src={faceIcon} alt="face icon" className="scanning-icon" />
              <p className="condition-checklist-message text-center mt-3 mb-0">
                { allConditionsMet && countDown >=0 ? strings.FINAL_CHECK.replace('{second}', String(countDown)): '\u00a0' } 
              </p>
            </div>
          </div>

          <div className="row">
            <div className="description d-flex flex-column pt-3 pb-3">
              <div className="row justify-content-center text-center d-flex flex-row">
                <Condition condition={strings.SCANNING_CONDITIONS.LIGHTING} status={props.conditions.lighting} />
                <Condition condition={strings.SCANNING_CONDITIONS.DISTANCE} status={props.conditions.distance} />
                <Condition condition={strings.SCANNING_CONDITIONS.CENTERED} status={props.conditions.centered} />
              </div>

              <div className="row justify-content-center text-center">
                <Condition condition={strings.SCANNING_CONDITIONS.MOVEMENT} status={props.conditions.movement} />
                {/* <Condition condition={'Frame Rate'} status={props.conditions.frameRate} /> */}
                <Condition condition={strings.SCANNING_CONDITIONS.SERVER_READY} status={props.conditions.serverReady} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ConditionChecklist;
