import { CONFIG } from "../config/Config"

const BUCKET = CONFIG.s3Bucket

export async function writeToS3Bucket(objectKey: string, data: any) {
  const url = 'https://aws-backend.panoptic.ai/s3/'


  let body = JSON.stringify({
    type: "save-json",
    params: {
      bucket: BUCKET,
      objectKey: `${process.env.NODE_ENV}/${objectKey}`,
      dataToSave: data
    }
  })

  return fetch(url, {
    method: 'POST',
    body,
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": CONFIG.apiKey
    }
  })
}

export async function readFromS3Bucket(objectKey: string):Promise<any> {

  const url = 'https://aws-backend.panoptic.ai/s3/'

  let body = JSON.stringify({
    type: "get-json",
    params: {
      bucket: BUCKET,
      objectKey: `${process.env.NODE_ENV}/${objectKey}`
    }
  })

  const result = await fetch(url, {
    method: 'POST',
    body,
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": CONFIG.apiKey
    }
  })

  const blob = await result.blob()
  const text = await blob.text()

  return JSON.parse(text)
}

