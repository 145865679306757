import { useNavigate } from 'react-router-dom';
import { useLocale } from '../../common/locale/LocaleProvider';
import { UserValidator } from '../../common/user/UserValidator';
import { useLogger, PageId, ActionId } from '../../common/user/Logger';
import LanguagePicker from '../../common/components/LanguagePicker';
import { useRef } from 'react';

import "bootstrap/dist/css/bootstrap.min.css";
import './TermsConditions.css';
import FwdHeader from "./../../common/components/FwdHeader"

export default function TermsConditions() {
    const { locale } = useLocale();
    const { strings } = locale;
    const { logAction } = useLogger(PageId.TermsConditions);
    const tcDescriptionRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();
    
    const startScanning = () => {
        logAction(ActionId.StartScaning);
        navigate('/scan');
    }

    return (
        <UserValidator>
          <>
          <LanguagePicker/>
            <div className="container-fluid h-100 overflow-hidden p-0" >
            <FwdHeader/>
                <div className="tc-page h-100 d-flex justify-content-center align-items-center">
            
                    {/* <div className="col-1 col-2"></div> */}
                    <div className="col-2 d-none d-md-block"></div>
                    
                    <div className="col-10 col-md-10 mt-5 mb-5 main-tc-content">
                        <div id="tc-page-content mx-auto">
                            
                            <div className="tc-page-header">   
                                <div className="tc-page-topbar d-flex align-items-center justify-content-center">
                                    <p className="tc-page-title text-center">{strings.TERMS_CONDITIONS_PAGE_TITLE}</p>
                                </div>
                            </div>

                            <div className="tc-page-description" ref={tcDescriptionRef}> 
                                <p>{strings.TERMS_CONDITIONS_PAGE_OPENING}</p>
                                <div className="tc-page-terms" >
                                    <ol className="text-start">
                                    {strings.TERMS_CONDITIONS_PAGE_CONTENT.map((item, index) => {
                                        return <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                                    })}
                                    </ol>
                                    <p dangerouslySetInnerHTML={{ __html: strings.TERMS_CONDITIONS_PAGE_CLOSING }} />
                                    <p>{strings.TERMS_CONDITIONS_PAGE_SIGNING}</p>
                                </div>

                                <div className="tc-button-container text-center">
                                    <button type="button" className="tc-button-back mx-1" onClick={()=>navigate(-1)}>{strings.TERMS_CONDITIONS_PAGE_BUTTONS.BACK}</button>
                                    <button type="button" className="tc-button-agree mx-1" onClick={startScanning}>{strings.TERMS_CONDITIONS_PAGE_BUTTONS.AGREE}</button>

                                </div>
                            </div>
                        </div>
                
                    </div>
                    
                    {/* <div className="col-1 col-2"></div>  */}
                    <div className="col-2 d-none d-md-block"></div>
                </div>
          </div>
          </>
        </UserValidator>
    )   
}


 