import {useEffect, useRef, useState} from 'react';
import { FACEMESH_TESSELATION } from '@mediapipe/face_mesh'
import { drawConnectors, NormalizedLandmarkList } from '@mediapipe/drawing_utils'
import { VideoSettings } from 'ts-vital-sign-camera';
import "./FaceMeshView.css";
import "./FaceMeshViewAnimation.css";

const FaceMeshView = (props:{landmarks: NormalizedLandmarkList|undefined, videoSettings:VideoSettings|undefined}) => {

    const container = useRef<HTMLDivElement|null>(null);
    const canvas = useRef<HTMLCanvasElement|null>(null);

    const [style, setStyle] = useState({});

    useEffect( () => {
        changeTransform();
    }, [props.videoSettings])

    const changeTransform = () => {
        if (!props.videoSettings) {
            return
        }
        canvas.current!.style.transform = props.videoSettings.transformX
    }

    const updateStyle = () => {

        let aspectRatio = 0
        const height = props.videoSettings?.height
        const width = props.videoSettings?.width
        aspectRatio = width && height ? width/height : 0;
        
        const style = {
            width: "",
            height: "",
            left: "0%",
            top: "0%",
            aspectRatio: aspectRatio,
        }
        
        if(!container.current) {
            return;
        }

        const containerAspectRatio = container.current?.clientWidth / container.current?.clientHeight;

        if(containerAspectRatio < aspectRatio) {
            style.left = `${ (containerAspectRatio - aspectRatio) / containerAspectRatio / 2 * 100 }%`;
            style.height = '100%';
        } else {
            style.top = `${ (1/containerAspectRatio - 1/aspectRatio) * containerAspectRatio / 2 * 100 }%`;
            style.width = '100%';
        }
        setStyle(style);
    }

    const redraw = () => {

        if(!canvas.current || !props.videoSettings) {
            return;
        }

        canvas.current.width = props.videoSettings.width
        canvas.current.height = props.videoSettings.height

        const ctx = canvas.current.getContext('2d');

        if (ctx !== null) {
            ctx.save();
            ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
            drawConnectors(
                ctx,
                props.landmarks,
                FACEMESH_TESSELATION,
                {
                    color: "lightgreen",
                    lineWidth: .2,
                }
            )
            ctx.restore();
        }
    }

    useEffect( () => {
        updateStyle();
        redraw();
    }, [props.landmarks, props.videoSettings])

    return (
        <div className='face-mesh-container' ref={container}>
            <canvas ref={canvas} style={style}></canvas>
        </div>
    );
}
export default FaceMeshView;