import { Routes, Route, HashRouter } from 'react-router-dom'
import Root from './pages/Root'
import Scan from './pages/scanning/Scan'
import Landing from './pages/landing/Landing'
import HealthResult from './pages/result/HealthResult'
import LuckyDraw from './pages/luckyDraw/LuckyDraw'
import Winners from './pages/luckyDraw/Winners'
import Submitted from './pages/luckyDraw/Submitted' /* testing */
import TermsConditions from './pages/termsConditions/TermsConditions'

import { LocaleProvider } from './common/locale/LocaleProvider'
import { UserProvider } from './common/user/UserProvider'

export default function App() {
  return (
    <LocaleProvider>
      <UserProvider>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Root />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/scan" element={<Scan />} />
            <Route path="/submitted" element={<Submitted />} />
            <Route path="/termsConditions" element={<TermsConditions />} />
            <Route path="/result" element={<HealthResult />} />
            <Route path="/luckyDraw" element={<LuckyDraw />} />
            <Route path="/winners" element={<Winners />} />
          </Routes>
        </HashRouter>
      </UserProvider>
    </LocaleProvider>
  )
}
