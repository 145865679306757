import { UserValidator } from '../../common/user/UserValidator';
import { useEffect, useState } from 'react';
import Submitted from './Submitted';
import { readFromS3Bucket, writeToS3Bucket } from '../../common/s3bucket/S3Bucket';
import { useUserContext } from '../../common/user/UserProvider';
import { PageId, useLogger } from '../../common/user/Logger';
import { useNavigate, Link } from 'react-router-dom';
import { useLocale } from '../../common/locale/LocaleProvider';
import LanguagePicker from '../../common/components/LanguagePicker';

import "bootstrap/dist/css/bootstrap.min.css";
import './LuckyDraw.css';
import {Collapse} from 'react-bootstrap';
import alarm from './assets/alarm.svg';
import clipboard from  './assets/clipboard.svg';
import heart from './assets/heart.svg';
import TermsConditions from './TermsConditions';
import arrowUp from './assets/arrow-up.svg';
import arrowDown from './assets/arrow-down.svg';

type State = 'init'
  | 'loading'
  | 'already-registered'
  | 'input'
  | 'submitting'
  | 'submitted'

type FormData = {
  fullName: string;
  hkid: string;
  phone: string;
  email: string;
  emailValidation: string;
  checkbox1: boolean;
  checkbox2: boolean;
  [key: string]: string | boolean;
};

export default function LuckyDraw() {
  const { locale } = useLocale()
  const { strings } = locale;

  useLogger(PageId.LuckyDraw)

  const userCtx = useUserContext()

  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    hkid: '',
    phone: '',
    email: '',
    emailValidation: '',
    checkbox1: false,
    checkbox2: false,
  })

  const [state, setState] = useState<State>('init')
  const navigate = useNavigate()
  const [localeChange, setLocaleChange] = useState(false);
  const [formErrors, setFormErrors] = useState({fullName: '', hkid: '', phone: '', email: '', emailValidation: '', checkbox1: '', checkbox2: ''})
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showTC, setShowTC] = useState(false);
  const [arrowIcon, setArrowIcon] = useState(false);
  const userId = userCtx.user?.userId

  useEffect(() => { // false when component mounts so form errors not displayed
    if (localeChange || formSubmitted) {
      formatValidator();
    } else {
      setLocaleChange(true);
    }
  }, [locale.strings.LANGUAGE_CODE, formSubmitted]);

  // useEffect(() => {
  //   if(formSubmitted) {
  //     formatValidator();
  //   }
  // }, [formSubmitted])

  const onChange = (type: keyof typeof formData, value: string | boolean) => { // typeof formErrors
    setFormData(prevState => ({ ...prevState, [type]: value }));

    const errors = { ...formErrors };
    delete errors[type as keyof typeof errors];

    setFormErrors(errors);
  }

  const formatValidator = async () => {
    const errors = { ...formErrors };


    if(formData.emailValidation !== formData.email) {
      errors.emailValidation = strings.LUCKY_DRAW_FORM_ERRORS.EMAILVALIDATION;
    } else {
      delete (errors as { [key: string]: string }).emailValidation;
    }

    for (const field in formData) {
      if (!formData[field as keyof typeof formData]) {
        // errors[field] = strings.LUCKY_DRAW_FORM_MISSING_FIELDS[field.toUpperCase())
        errors[field as keyof typeof errors] = strings.LUCKY_DRAW_FORM_MISSING_FIELDS[field.toUpperCase() as keyof typeof strings.LUCKY_DRAW_FORM_MISSING_FIELDS];
      }
    }

    if(!/^[a-zA-Z\u4e00-\u9fff\s]*$/.test(formData.fullName)) {
      errors.fullName = strings.LUCKY_DRAW_FORM_ERRORS.FULLNAME;
    }

    if (!formData.email.match(/^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || formData.email.toLowerCase() !== formData.email) {
      errors.email = strings.LUCKY_DRAW_FORM_ERRORS.EMAIL;
    }

    // if (!formData.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || formData.email.toLowerCase() !== formData.email) {
    //   errors.email = strings.LUCKY_DRAW_FORM_ERRORS.EMAIL;
    // }

    // if (!formData.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.) +[a-zA-Z]{2,}))$/) || formData.email.toLowerCase() !== formData.email) {
    //   errors.email = strings.LUCKY_DRAW_FORM_ERRORS.EMAIL;
    // }

    // if (!/\S+@\S+\.\S+/.test(formData.email) && !formData.email.includes("-") && formData.email.toLowerCase() !== formData.email) {
    //   errors.email = strings.LUCKY_DRAW_FORM_ERRORS.EMAIL;
    // }

    // if(formData.emailValidation !== formData.email) {
    //   errors.emailValidation = strings.LUCKY_DRAW_FORM_ERRORS.EMAILVALIDATION;
    // }

    if(formData.phone !== "" && (formData.phone.replace(/\D/g, "") !== formData.phone || !/^\d{8}$/.test(formData.phone.replace(/\D/g, "")))) { // 8 to 11
      errors.phone = strings.LUCKY_DRAW_FORM_ERRORS.PHONE;
    }

    if(formData.hkid !== "" && !/^[a-zA-Z]{1,2}\d{3}$/.test(formData.hkid)) {
      errors.hkid = strings.LUCKY_DRAW_FORM_ERRORS.HKID;
    }

    // if(phoneNumber) {
    //   const digitsOnly = phoneNumber.replace(/\D/g, "");
    //   const isValid = /^\d{8,11}$/.test(digitsOnly);
    //   if (!isValid) {
    //     errors.phoneNumber = "Invalid phone number";
    //   }
    // }
    const fullName = `${formData.fullName.trim().toLowerCase().replaceAll(' ', '-')}`
    const hkid = `${formData.hkid.trim().toLowerCase()}`
    const luckyDrawInfoFile = `luckyDraw/${hkid}_${fullName}.json`

    const prevRegistration = await readFromS3Bucket(luckyDrawInfoFile)
    if (typeof prevRegistration === 'object') {
      errors.fullName = strings.LUCKY_DRAW_FORM_ERRORS.NAME_ALREADY_REGISTERED
      errors.hkid = strings.LUCKY_DRAW_FORM_ERRORS.HKID_ALREADY_REGISTERED
    }

    if(formSubmitted) {
      setFormErrors(errors)
    }
    // setFormErrors(errors);

    if (Object.keys(errors).length) {
      return true;
    } else {
      return false;
    }
  }

  const submit = async (event:React.FormEvent) => {
    // setFormSubmitted = true;
    event.preventDefault()

    if (userId === undefined) {
      return;
    }

    setFormSubmitted(true)

    if (await formatValidator()) return;

    // if (!fullName || fullName.trim() === '' ||
    //     !hkid || hkid.trim() === '' ||
    //     !email || email.trim() === '' ||
    //     !phoneNumber || phoneNumber.trim() === '') {
    //   return
    // }

    const fullName = `${formData.fullName.trim().toLowerCase().replaceAll(' ', '-')}`
    const hkid = `${formData.hkid.trim().toLowerCase()}`
    const luckyDrawInfoFile = `luckyDraw/${hkid}_${fullName}.json`

    console.log('Submiiting registration.')
    const timestamp = new Date().getTime()
    writeToS3Bucket(luckyDrawInfoFile, {...formData, userId, timestamp}
    ).then( ()=> {
      setState('submitted')
    }).catch( ()=> {

    })
  }

  const toggleTerms = () => {
    setShowTC(!showTC)
    setArrowIcon(!arrowIcon)
  }

  if (state === 'loading') {
    return <>Loading...</>
  }
  if (state === 'submitted') {
    return <Submitted/>
  }
  // if (state === 'already-registered') {
  //   return <AlreadyRegistered/>
  // }

  return (
    <UserValidator>
      <>
      <div className="container-fluid h-100 overflow-scroll">
        <LanguagePicker luckyDraw={true}/>
          <div className="lucky-draw-page">
            <div className="lucky-draw-page-upper row h-50">
              <div className="col-0 col-md-2 p-0"></div>
              <div className="col-12 col-md-8 h-100 d-flex justify-content-center flex-column mx-auto">
                <p className="lucky-draw-page-title text-center mt-5 mb-0">{strings.LUCKY_DRAW_TITLE}</p>
                <p className="lucky-draw-page-description text-left mx-auto d-none d-md-block">{strings.LUCKY_DRAW_DESCRIPTION}</p>
                <p className="lucky-draw-page-description text-left mx-auto d-block d-md-none">{strings.LUCKY_DRAW_DESCRIPTION_MOBILE}</p>

                <p className="lucky-draw-instructions-title text-center p-0">{strings.LUCKY_DRAW_INSTRUCTIONS_TITLE}</p>

                <div className="row mx-0">
                    <div className="col-12 col-md-4 mb-3 d-flex justify-content-center">
                      <div className="instructions-box p-3 mx-auto">
                        <div className="text-center">
                          <img className="lucky-draw-steps-icon mb-2" src={heart} alt="heart"></img>
                        </div>
                          <p className="lucky-draw-steps-title mb-1">1. {strings.LUCKY_DRAW_INSTRUCTIONS.STEP_ONE_TITLE}</p>
                          <p className="lucky-draw-steps-description">{strings.LUCKY_DRAW_INSTRUCTIONS.STEP_ONE_DESCRIPTION}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 mb-3 d-flex justify-content-center">
                      <div className="instructions-box p-3 mx-auto">
                        <div className="text-center">
                          <img className="lucky-draw-steps-icon mb-2" src={clipboard} alt="clipboard"></img>
                        </div>
                        <p className="lucky-draw-steps-title mb-1">2. {strings.LUCKY_DRAW_INSTRUCTIONS.STEP_TWO_TITLE}</p>
                        <p className="lucky-draw-steps-description">{strings.LUCKY_DRAW_INSTRUCTIONS.STEP_TWO_DESCRIPTION}</p>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 mb-3 d-flex justify-content-center" >
                      <div className="instructions-box p-3 mx-auto">
                        <div className="text-center">
                          <img className="lucky-draw-steps-icon lucky-draw-icon-three mb-2" src={alarm} alt="alarm"></img>
                        </div>
                          <p className="lucky-draw-steps-title mb-1">3. {strings.LUCKY_DRAW_INSTRUCTIONS.STEP_THREE_TITLE}</p>
                          <p className="lucky-draw-steps-description">{strings.LUCKY_DRAW_INSTRUCTIONS.STEP_THREE_DESCRIPTION}</p>
                      </div>
                    </div>
                </div>
                <p className="tc-note text-center">
                  <button className="btn tc-button" aria-expanded={showTC} aria-controls="tc-content" onClick={toggleTerms}>{strings.LUCKY_DRAW_TC_NOTE}
                  <img src={arrowIcon ? arrowUp : arrowDown} alt="arrow-button-icon" className="arrow-icon"/>
                  </button>
                </p>
                <Collapse in={showTC}>
                  <div id="tc-content"><TermsConditions/></div>
                </Collapse>
              </div>
              <div className="col-0 col-md-2 p-0 h-100"></div>
            </div>

            <div className="lucky-draw-page-lower row h-50 text-center">
              <div className="col-0 col-md-1 p-0 h-100"></div>
              <div className="col-12 col-md-10 d-flex justify-content-center flex-column h-100">

                <p className="lucky-draw-form-title pt-3">{strings.LUCKY_DRAW_FORM_TITLE} </p>

                <form onSubmit={e => submit(e) }>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group text-start mb-3">
                        <label htmlFor="fullName">{strings.LUCKY_DRAW_FORM_LABELS.NAME}</label>
                        <input type="text" className={`${formErrors.fullName? 'input-error' : ''} form-control p-3 mx-auto mt-2`} id="fullName" value={formData.fullName} placeholder={strings.LUCKY_DRAW_FORM_PLACEHOLDERS.FULLNAME} onChange={e=>onChange("fullName", e.target.value)} />
                        {formSubmitted && formErrors.fullName && <div className="form-error text-danger">{formErrors.fullName}</div>}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group text-start mb-3" >
                        <label htmlFor="hkid">{strings.LUCKY_DRAW_FORM_LABELS.HKID}</label>
                        <input type="text" className={`${formErrors.hkid? 'input-error' : ''} form-control p-3 mx-auto mt-2`} id="hkid" value={formData.hkid} placeholder={strings.LUCKY_DRAW_FORM_PLACEHOLDERS.HKID} onChange={e=>onChange("hkid", e.target.value)} />
                        {formSubmitted && formErrors.hkid && <div className="form-error text-danger">{formErrors.hkid}</div>}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group text-start mb-3">
                        <label htmlFor="phone">{strings.LUCKY_DRAW_FORM_LABELS.PHONE}</label>
                        <input type="tel" className={`${formErrors.phone? 'input-error' : ''} form-control p-3 mx-auto`} id="phone" value={formData.phone} placeholder={strings.LUCKY_DRAW_FORM_PLACEHOLDERS.PHONE} onChange={e=>onChange("phone", e.target.value)} />
                        {formSubmitted && formErrors.phone && <div className="form-error text-danger">{formErrors.phone}</div>}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group text-start mb-3">
                        <label htmlFor="email">{strings.LUCKY_DRAW_FORM_LABELS.EMAIL}</label>
                        <input type="email" className={`${formErrors.email? 'input-error' : ''} form-control p-3 mt-2 mx-auto`} id="email" value={formData.email} placeholder={strings.LUCKY_DRAW_FORM_PLACEHOLDERS.EMAIL} onChange={e=>onChange("email",e.target.value)} />
                        {formSubmitted && formErrors.email && <div className="form-error text-danger">{formErrors.email}</div>}
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group text-start mb-3">
                        <label htmlFor="emailValidation">{strings.LUCKY_DRAW_FORM_LABELS.EMAIL_VALIDATION}</label>
                        <input type="email" className={`${formErrors.emailValidation? 'input-error' : ''} form-control p-3 mt-2 mx-auto`} id="emailValidation" value={formData.emailValidation} placeholder={strings.LUCKY_DRAW_FORM_PLACEHOLDERS.EMAILVALIDATION} onChange={e=>onChange("emailValidation", e.target.value)} />
                        {formSubmitted && formErrors.emailValidation && <div className="form-error text-danger">{formErrors.emailValidation}</div>}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex text-start">
                    <div className="form-group mt-2">
                      <div className="user-consent form-check">
                        <input className={`${formErrors.checkbox1? 'input-error' : ''} form-check-input`} type="checkbox" id="checkbox1" checked={formData.checkbox1} onChange={(e) => onChange('checkbox1', e.target.checked)}  />
                        <label className="form-check-label" htmlFor="checkbox1">
                          {strings.LUCKY_DRAW_USER_CONSENT.CHECKBOX_ONE}
                        </label>
                        {formSubmitted && formErrors.checkbox1 && <div className="form-error text-danger checkbox-error">{formErrors.checkbox1}</div>}
                      </div>

                      <p className="user-consent-footnote mt-3" style={{fontWeight: 500}} dangerouslySetInnerHTML={{ __html: strings.LUCKY_DRAW_USER_CONSENT.PERSONAL_INFO_NOTE }} />
                      <div className="user-consent form-check">
                        <input className={`${formErrors.checkbox2? 'input-error' : ''} form-check-input`} type="checkbox" id="checkbox2" checked={formData.checkbox2} onChange={(e) => onChange('checkbox2', e.target.checked)}  />
                        <label className="form-check-label" htmlFor="checkbox2">
                        {strings.LUCKY_DRAW_USER_CONSENT.CHECKBOX_TWO}
                        </label>
                        {formSubmitted && formErrors.checkbox2 && <div className="form-error text-danger checkbox-error">{formErrors.checkbox2}</div>}
                      </div>
                      <p className="user-consent-footnote mt-3">{strings.LUCKY_DRAW_USER_CONSENT.CONFIRM_SUBMIT}</p>
                    </div>

                  </div>

                  <div className="d-flex justify-content-center flex-column">
                    <button type="submit" className="lucky-draw-form-button lucky-draw-confirm-button mx-auto mt-4">{strings.LUCKY_DRAW_BUTTONS.DONE}</button>
                    <button className="lucky-draw-form-button lucky-draw-back-button mx-auto mb-5" onClick={()=>navigate(-1)}>{strings.LUCKY_DRAW_BUTTONS.BACK}</button>
                  </div>

                </form>
              </div>
              <div className="outer col-0 col-md-1 h-100"></div>

            </div>
          </div>
      </div>
      </>
    </UserValidator>
  )
}
