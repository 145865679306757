import { createContext, useContext, useReducer, useMemo, useEffect, useState } from "react";
import { Health } from "react-vital-sign-camera";
import { useSearchParams } from "react-router-dom";
import { CONFIG } from "../config/Config"

interface User {
  userId: string
  health?: Health
}

interface UserContextAction {
  type: 'NEW_USER'|'SET_HEALTH'
  payload: {
    userId?: string
    health?: Health
  }
}

export interface UserContext {
  user?: User
  dispatch(action: UserContextAction):void
}

const initialContext:UserContext = {
  user: undefined,
  dispatch: (action:UserContextAction) => {}
}

const UserReactContext = createContext(initialContext)

function reducer(state:UserContext, action:UserContextAction):UserContext {
  switch(action.type) {
    case 'NEW_USER':
      if (action.payload.userId) {
        return {...state, user: {userId: action.payload.userId}}
      }
      return state
    case 'SET_HEALTH':
      if (action.payload.health && state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            health: action.payload.health
          }
        }
      }
      return state
    default:
      return state
  }

}

export function UserProvider(props: { children: React.ReactElement<any, string | React.JSXElementConstructor<any>> }) {

  const [ctx, dispatch] = useReducer(reducer, initialContext)

  return (
    <UserReactContext.Provider value={{user: ctx.user, dispatch}}>
      {props.children}
    </UserReactContext.Provider>
  )
}

export function useUserContext() {
  return useContext(UserReactContext)
}

export function useUserFromParam() {

  const userCtx = useUserContext()
  const [searchParams] = useSearchParams()

  let userId = searchParams.get('id') || sessionStorage.getItem('userId')

  if (process.env.NODE_ENV === 'development') {
    if (!userId) {
      console.log('Using default user id: ', CONFIG.defaultDevUserId)
      userId = CONFIG.defaultDevUserId
    }
  }

  useEffect(()=> {
    if (userId) {
      sessionStorage.setItem('userId', userId)
      userCtx.dispatch({type: 'NEW_USER', payload:{ userId }})
    } else {
      console.log('Missing User')
    }
  }, [userCtx, userId])

  return !!userId

}
