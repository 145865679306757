import { ALL_LANGUAGE_CODES, LOCALE_STRINGS, LanguageCode } from '../locale/LocaleStrings';
import { useLocale } from '../locale/LocaleProvider';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './LanguagePicker.css';

function LanguageButton(props:{code:LanguageCode, active: boolean, toggleActiveButton: () => void}) {
  const {locale, changeLocale} = useLocale();
  const selected = locale.strings.LANGUAGE_CODE === props.code
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <button
      className={`language-button ${props.active ? 'active' : ''}`}
      key={props.code}
      disabled={selected}
      onClick={ ()=> {
        if (searchParams.get('language')) {
          console.log('setting languge search param', props.code)
          searchParams.set('language', props.code)
          setSearchParams(searchParams)
        }
        changeLocale(props.code);
        props.toggleActiveButton();
      }
    }>
      { LOCALE_STRINGS[props.code].LANGUAGE_SHORT_NAME }
    </button>
  )
}

export default function LanguagePicker(props:{luckyDraw?: boolean}) {
  const {locale} = useLocale()
  const [activeButton, setActiveButton] = useState<LanguageCode | null>(locale.strings.LANGUAGE_CODE);

  const toggleActiveButton = (code: LanguageCode) => {
    setActiveButton(code);
  }

  return (
    <div className="language-picker-wrapper">
      <div className={`${props.luckyDraw ? 'language-picker-lucky-draw' : 'language-picker'}`}>{
        ALL_LANGUAGE_CODES.map( code => (
          <LanguageButton
            key={code}
            code={code}
            active={code === activeButton}
            toggleActiveButton={() => toggleActiveButton(code)}
          />
        )).reduce(
          (prevValue, currentValue, currentIndex, array) => {
            prevValue.push(currentValue)
            if (currentIndex < array.length - 1) {
              prevValue.push(<span className="language-separator" key={currentIndex}>|</span>)
            }
            return prevValue
          },
          [] as JSX.Element[]
        )
      }</div>
    </div>
  )
}
