import { useLocale } from '../../common/locale/LocaleProvider';
import LanguagePicker from '../../common/components/LanguagePicker';

import "bootstrap/dist/css/bootstrap.min.css";
import './Winners.css';

import balloons from './assets/balloons.svg';
import winners from './assets/winners.png';
import arrowUp from './assets/arrow-up-black.svg';
import arrowDown from './assets/arrow-down-black.svg';
import { useState } from 'react';
import TermsConditions from './TermsConditions';
import { Collapse } from 'react-bootstrap';

export default function Submitted() {
  const { locale } = useLocale();
  const { strings } = locale;
  const [showTC, setShowTC] = useState(false);
  const [arrowIcon, setArrowIcon] = useState(false);

  const toggleTerms = () => {
    setShowTC(!showTC)
    setArrowIcon(!arrowIcon)
  }

  return (
    <>
      <div className="container-fluid h-100 p-0">
        <LanguagePicker luckyDraw={true}/>
          <div className="top-bar w-100"></div>
            <div className="lucky-draw-submitted-page">
              <div className="row">
                <div className="col-1 col-md-2 col-md-2 p-0"></div>
                  <div className="col-10 col-md-8 text-center d-flex justify-content-center flex-column text-center px-3" >
                      <div className="lucky-draw-content mx-auto">
                        <p className="lucky-draw-submitted-title text-center mt-3 pt-5 mb-0 position-absolute top-0 start-50 translate-middle">{strings.LUCKY_DRAW_WINNERS.TITLE}</p>
                        <img src={balloons} alt="balloons" className="lucky-draw-submitted-icon"></img>
                        <h3 className="mt-5">{strings.LUCKY_DRAW_WINNERS.TABLE_HEAD}</h3>
                        <h3>{strings.LUCKY_DRAW_WINNERS.TABLE_SUB_HEAD}</h3>
                        <div className="lucky-draw-submitted-message mt-4">
                          <img src={winners} alt="winners"></img>
                        </div>
                        <div className="mt-5">{strings.LUCKY_DRAW_WINNERS.TABLE_FOOTER}</div>
                <p className="tc-note text-center">
                  <button className="btn tc-button" aria-expanded={showTC} aria-controls="tc-content" onClick={toggleTerms}>{strings.LUCKY_DRAW_TC_NOTE}
                  <img src={arrowIcon ? arrowUp : arrowDown} alt="arrow-button-icon" className="arrow-icon"/>
                  </button>
                </p>
                <Collapse in={showTC}>
                  <div id="tc-content"><TermsConditions/></div>
                </Collapse>

                      </div>

                  </div>
                <div className="col-1 col-md-2 p-0"></div>
              </div>
            </div>
      </div>
    </>
  )
}
