import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocale } from '../../common/locale/LocaleProvider';
import { UserValidator } from '../../common/user/UserValidator';
import { useLogger, PageId, ActionId } from '../../common/user/Logger';
import LanguagePicker from '../../common/components/LanguagePicker';

import "bootstrap/dist/css/bootstrap.min.css";
import "./Landing.css";
import animals from "./assets/animals.png";
import animalsMobile from "./assets/animals-mobile.png";
import circlesBird from "./assets/circles-bird.svg";

const version = require('../../../package.json').version;

export default function Landing() {
  const { locale } = useLocale();
  const { strings } = locale;
  const { logAction } = useLogger(PageId.Landing);
  
  const [localTitleStyles, setLocalTitleStyles] = useState('');
  const [localSubtitleStyles, setLocalSubtitleStyles] = useState('');
  const [localLandingButtonStyles, setLocalLandingButtonStyles] = useState('');

  const navigate = useNavigate();

  const startScanning = () => {
    logAction(ActionId.StartScaning);
    navigate('/termsConditions');
  }

  useEffect(() => {
    setLocalTitleStyles(locale.strings.LANGUAGE_CODE === 'en' ? 'landing-page-title-en' : 'landing-page-title-zh');
    setLocalSubtitleStyles(locale.strings.LANGUAGE_CODE === 'en' ? 'landing-page-subtitle-en' : 'landing-page-subtitle-zh');
    setLocalLandingButtonStyles(locale.strings.LANGUAGE_CODE === 'en' ? 'landing-start-button-en' : 'landing-start-button-zh');
  }, [locale]);

  return (
    <UserValidator>
      <>
      <LanguagePicker/>
        <div className='version'>{version}</div>
        <div className="container-fluid h-100">
          <div className="landing-page">
          <div className="row h-75">

            <div className="col-2 d-none d-md-block"></div>

            <div className="col-12 col-md-8 d-flex align-items-center justify-content-center position-relative">

              <div className="circles-container">
                <div className="landing-page-ellipse-outermost position-absolute top-50 start-50 translate-middle rounded-circle mx-auto">
                  <div className="landing-page-ellipse-outer position-absolute top-50 start-50 translate-middle rounded-circle mx-auto">
                    <div className="landing-page-ellipse-inner position-absolute top-50 start-50 translate-middle rounded-circle mx-auto">
                      <div className="landing-page-ellipse-innermost position-absolute fw-bold top-50 start-50 translate-middle bg-white rounded-circle text-center d-flex justify-content-center flex-column p-3">

                        <p className={`mb-0 lh-sm mt-auto ${localTitleStyles}`}>{strings.LANDING_ELLIPSE_TITLE}</p>
                        <p className={`lh-sm ${localSubtitleStyles}`}>{strings.LANDING_ELLIPSE_SUBTITLE}</p>
                        <img src={circlesBird} alt="circles-and-bird" className="circles-bird position-absolute"/>
                        {/* <div className="mt-auto mx-auto button-container position-absolute d-flex align-items-center justify-content-center"> */}
                          {/* <button type="button" className="landing-start-button position-fixed fw-bold btn bg-white rounded-circle px-4 mb-0 mx-auto" onClick={startScanning}>{strings.LANDING_START_BUTTON_TITLE}</button> */}
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
              <button type="button" className={`position-absolute fw-bold btn bg-white rounded-circle px-4 d-flex justify-content-center align-items-center ${localLandingButtonStyles}`}  onClick={startScanning}>{strings.LANDING_START_BUTTON_TITLE}</button>
            </div>

            <div className="col-2 d-none d-md-block"></div>
          </div>

          <div className="row h-25">
            <div className="col-2"></div>
              <div className="col-8 h-100 mb-5">
                <div className="animals-container d-flex justify-content-center">
                  <img src={animals} alt="animals" className="mb-0 d-none d-sm-block"/>
                  <img src={animalsMobile} alt="animals-mobile" className="animals-mobile mb-0 d-block d-sm-none"/>
                </div>
              </div>
            <div className="col-2"></div>
          </div>

        </div>
      </div>
      </>
    </UserValidator>
  )
}
