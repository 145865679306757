import { LocaleStrings } from "../../common/locale/LocaleStrings";

export enum ErrorId {
  unknown,
  faceLost,
  noCameraPermission
}

export class ScanError {
  id: ErrorId
  message: string
  buttonText: string
  constructor(error:any, localeStrings:LocaleStrings) {

    const errorMessage = `${error}`
    if (errorMessage === "Error: face lost") {
      this.message = localeStrings.SCANNING_ERROR.FACE_LOST;
      this.id = ErrorId.faceLost
      this.buttonText = localeStrings.SCANNING_ERROR.RETRY
    } else if (errorMessage.startsWith('NotAllowedError:')) {
      this.message = localeStrings.CAMERA_PERMISSION_ERROR;
      this.id = ErrorId.noCameraPermission
      this.buttonText = localeStrings.ERROR_MODAL_BUTTON_OK
    } else {
      this.message = `${localeStrings.SCANNING_ERROR.OTHER} (${error})`;
      this.id = ErrorId.unknown
      this.buttonText = localeStrings.SCANNING_ERROR.RETRY
    }
  
  }
}