import { useLocale } from '../../common/locale/LocaleProvider';
// import { useLogger, PageId, ActionId } from '../../common/user/Logger';
import loadingIcon from "./assets/icon-bw.gif";

import "bootstrap/dist/css/bootstrap.min.css";
import "./AnalyzingResults.css";

export default function AnalyzingResults() {
  const { locale } = useLocale();
  const { strings } = locale;
  // const { logAction } = useLogger(PageId.AnalyzingResults)

  // useLogger(PageId.AnalyzingResults)

  return (
    <>
    <div className="content">
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-2"></div>

            <div className="col-8 d-flex align-items-center justify-content-center">
              <div className="analyzing-circles-container"> 
                <div className="analyzing-page-ellipse-outermost position-absolute top-50 start-50 translate-middle rounded-circle mx-auto">
                  <div className=" analyzing-page-ellipse-outer position-absolute top-50 start-50 translate-middle rounded-circle mx-auto">
                    <div className="analyzing-page-ellipse-inner position-absolute top-50 start-50 translate-middle rounded-circle mx-auto">
                      <div className="analyzing-page-ellipse-innermost position-absolute fw-bold top-50 start-50 translate-middle bg-white rounded-circle text-center d-flex justify-content-center align-items-center flex-column">
                        <p className="analyzing-page-title mt-5">{strings.ANALYZING_RESULTS_TEXT}</p>
                        <img className="mx-auto" src={loadingIcon} alt="loading..." width="80" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
        <div className="col-2"></div>
      </div>

    </div>
    </div>
    </>
  )
}
