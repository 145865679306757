import { GetHealthStage } from 'react-vital-sign-camera';
import "./Status.css";
import CircularProgress from './CircularProgress';

const Status = (props: { state: GetHealthStage | undefined, remainingTime?: number }) => {

  let text;

  if (props.state === undefined || props.state === GetHealthStage.Idle) {
    return <></>
  }

  switch (props.state) {
    case GetHealthStage.CollectingData:
      text = "Scanning...";
      break;
    case GetHealthStage.AnalyzingData:
      text = "Analyzing...";
      break;
    case GetHealthStage.WaitingData:
      text = "Loading...";
      break;
  }

  let number;
  if (props.remainingTime) {
    number = props.remainingTime.toFixed(0);
    if (number === 'Infinity' || number === '-1') {
      number = undefined;
    }
  }

  return (
    <>
    <div className="statusContainer">
      {/* {number && (
        <div className="countdown">{number}</div>
      )}
      <div className="text">{text}</div>
    </div> */}

    {number && (
        <div className="countdown">
        <CircularProgress timeLeft={props.remainingTime} radius={72.5} stroke={14.5} text={text}/>
          {/* <div className="text mx-auto">{text}</div> */}
        </div>
    )}

    {/* {props.state === GetHealthStage.CollectingData && <CircularProgress radius={72.5} stroke={14.5} />} */}
   </div>
    </>
  );
}

export default Status;
