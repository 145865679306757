import { useLocale } from '../../common/locale/LocaleProvider';
import "./ErrorModal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ScanError } from './ScanError';

interface ErrorModalProps {
  error: ScanError;
  onDismiss: (error:ScanError) => void;
  // refreshScanPage: () => void;
}

export default function ErrorModal({ error, onDismiss }: ErrorModalProps) {

  const { locale } = useLocale();
  const { strings } = locale;

  return (
    <>
      <div className="modal d-flex justify-content-center align-items-center rounded p-0">
        <div className="modal-content p-0">
          <div className="modal-header" >
            <h5 className="modal-title fw-bold">{strings.SCANNING_ERROR.TITLE}</h5>
          </div>

          <div className="modal-body">
            {error.message}
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={()=>onDismiss(error)}>
              {error.buttonText}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}