import "./Scan.css";
import { useCallback, useState, useEffect, useRef } from 'react';
import { VitalSignCamera, VitalSignCameraInstance } from 'react-vital-sign-camera';
import { Gender, GetHealthResult, GetHealthStage, NormalizedFacebox, ScanConditions, VideoFrameProcessedEvent } from 'react-vital-sign-camera';
import ConditionChecklist from './ConditionChecklist';
import Status from './Status';
// import BoundingBox from './BoundingBox';
import FaceMeshView from './FaceMeshView';
import { NormalizedLandmarkList } from '@mediapipe/drawing_utils';
import { useNavigate } from 'react-router-dom';
import { VideoSettings, VideoSettingsUpdatedEvent } from 'ts-vital-sign-camera';
import { UserValidator } from '../../common/user/UserValidator';
import { useUserContext } from '../../common/user/UserProvider';
import { useLocale } from '../../common/locale/LocaleProvider';

import { CONFIG } from '../../common/config/Config'
import { PageId, useLogger } from '../../common/user/Logger';
import LanguagePicker from '../../common/components/LanguagePicker';
import silhouette from './assets/silhouette.png';

import FwdHeader from "../../common/components/FwdHeader";
import AnalyzingResults from "../analyzing/AnalyzingResults";

import ErrorModal from "./ErrorModal";
import Loading from "./Loading";
import { ErrorId, ScanError } from "./ScanError"

const USERINFO = {
  age: 30,
  gender: Gender.Male,
  userId: CONFIG.userId
}

export type Device = {
  text: string
  id: string
  deviceIndex: number
}

function Home() {

  const { locale } = useLocale();
  const { strings } = locale;
  // const { logAction } = useLogger(PageId.Landing)

  const navigate = useNavigate();

  const userCtx = useUserContext();

  const [camera, setCamera] = useState<VitalSignCameraInstance|undefined>(undefined)
  const [result, setResult] = useState<GetHealthResult|undefined>(undefined);
  const [show, setShow] = useState<boolean>(false);
  const [conditions, setConditions] = useState<ScanConditions|undefined>();
  const [state, setState] = useState<'idle'|'started'>('idle');
  const [facebox, setFacebox] = useState<NormalizedFacebox|undefined>(undefined);
  const [landmarks, setLandmarks] = useState<NormalizedLandmarkList|undefined>(undefined);
  // const [cameraIndex, setCameraIndex] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const checkDevice = useRef<number|null>();
  const [videoSettings, setVideoSettings] = useState<VideoSettings>()
  const [error, setError] = useState<ScanError|undefined>();

  const screen = useRef<HTMLDivElement|null>(null);

  const isAnalyzing = result?.stage === GetHealthStage.AnalyzingData ||
        (result?.stage === GetHealthStage.Idle && result.health !== undefined)

  const [initializing, setInitializing] = useState(true);

  useLogger(PageId.Scanning)

  useEffect(() => {
    // turn on camera
    setIsActive(true);

    // reset the vital signs
    userCtx.dispatch({type:'SET_HEALTH', payload:{health: undefined}})
  }, [])

  useEffect(() => {
    (async () => {if (state === 'started' && result?.stage === GetHealthStage.Idle) {
      setState('idle');
    }
    if (result?.stage === GetHealthStage.Idle && result.health !== undefined) {
      // set vital signs in the context
      userCtx.dispatch({type: 'SET_HEALTH', payload: {health: result.health}});

      // turn off camera
      setIsActive(false);

      // wait until camera is completely turned off
      await camera?.waitCameraToStop();

      // navigate to the next page
      navigate('/result')
    }})()
  }, [result])

  // useEffect( () => {
  //   if (state === 'idle' && conditions && Object.values(conditions).every(item => item === true)) {
  //     setAllConditionsMet(true);
  //     startScanning();
  //   } else {
  //     setAllConditionsMet(false);
  //   }
  //   // console.log('allConditionsMet: ' + allConditionsMet)
  // }, [conditions])

  const onCameraCreated = (camera:VitalSignCameraInstance) => {
    setCamera(camera);
  }

  const handleError = (processedFrame:VideoFrameProcessedEvent) => {
    const error = processedFrame.healthResult?.error
    if (!error) {
      return
    }
    setShow(false)
    setError(error)

  }

  const toggleErrorModal = () => {
    setError(undefined)
  };

  const refreshScanPage = () => {
    navigate(0)
    // setState('idle')
    // window.location.reload();
    // window.location.href="/scan"
    // window.location.href = window.location.href;
  }

  const onVideoFrameProcessed = useCallback( (processedFrame:VideoFrameProcessedEvent)=> {
    // save the obtained variables from the VitalSignCamera
    setResult(processedFrame.healthResult);
    setConditions(processedFrame.scanConditions);
    setFacebox(processedFrame.facebox);
    setLandmarks(processedFrame.landmarks);
    // handleError(processedFrame)
    // console.log(processedFrame.facebox)
  }, [])

  const onVideoSettingsUpdated = useCallback( (event:VideoSettingsUpdatedEvent)=>{
    setVideoSettings(event.settings)
  }, [])

  const onInitialized = useCallback( ()=> {
    setInitializing(false)
  }, [])

  // const startScanning = () => {
  //   // start vital sign scanning
  //   if(allConditionsMet) {
  //     camera?.startScanning();
  //     setShow(true);
  //     setError(undefined)
  //     setState('started');
  //   }
  // }

  const startScanning = () => {
    if(state === 'started') {
      throw new Error('already started');
    }

    camera?.startScanning();
    setShow(true);
    setError(undefined)
    setState('started');
  }

  // const switchCamera = () => {
  //   // switch to another camera device
  //   setCameraIndex((cameraIndex+1) % devices.length)
  // }

  const goBack = async () => {
    setIsActive(false);
    await camera?.waitCameraToStop()
    navigate(-1)
  }

  const dismissErrorModal = (error:ScanError) => {
    switch(error.id) {
      case ErrorId.unknown:
      case ErrorId.faceLost: 
        goBack()
        break
      case ErrorId.noCameraPermission: 
        break
    }
  } 

  return (
    <UserValidator>
      <>

      <LanguagePicker/>
      <div className="App screen scanning-page" ref={screen} style={{display: isAnalyzing ? "none" : "flex"}}>

      {/* {state === 'idle' && <button className='startButton' onClick={startScanning} disabled={conditions ? !Object.values(conditions).every(item => item) : false}>
        START
      </button>} */}
      <FwdHeader/>
      <div className="videoContainer">

        <VitalSignCamera
          isActive={isActive}
          config={CONFIG}
          userInfo={USERINFO}
          maxFaceDetectionFps={5}
          onVideoFrameProcessed={onVideoFrameProcessed}
          onVideoSettingsUpdated={onVideoSettingsUpdated}
          onCreated={onCameraCreated}
          onInitialized={onInitialized}
          onError={err=>setError(new ScanError(err, strings))}
          disableAgeEstimation={true}
        />
      </div>
      {initializing && <Loading />}
      {!initializing && <img src={silhouette} alt="scanning-silhouette" className="silhouette" />}
      {/* {facebox !== undefined && isActive && <BoundingBox facebox={facebox} parent={screen.current} videoSettings={videoSettings}/>} */}
      {facebox !== undefined && isActive && <FaceMeshView landmarks={landmarks} videoSettings={videoSettings}/>}
      {state === 'idle' && !show && conditions && !error && (
        <ConditionChecklist conditions={conditions} onCountDownFinished={ ()=> startScanning() }/>
      ) }
      <Status state={result?.stage} remainingTime={result?.remainingTime} />
      {/* {devices && devices.length > 1 && state === 'idle' && <button className="cameraSwitchButton" onClick={switchCamera}>Switch Camera</button>}  */}
      {/* <button className="goBackButton" onClick={goBack}>{strings.SCANNING_PAGE_GO_BACK}</button> */}

      {/* {error && <div className="error">{`${error}`}</div>} */}
      { error && <ErrorModal error={error} onDismiss={dismissErrorModal}/> }

      </div>
      {isAnalyzing && <AnalyzingResults />}
      </>
    </UserValidator>
  )
}

export default Home
