import "bootstrap/dist/css/bootstrap.min.css";
import "./CircularProgressResult.css";
import { useState, useEffect, useMemo } from 'react';

type CircularProgressResultProps = {
    currentScore: number | undefined; // default: null
    maxScore: number | undefined;
    radius: number;
    stroke: number;
    strokeColor1: string;
    strokeColor2: string;
    text: string;
    stress: boolean;
}

const CircularProgressResult = ({ currentScore, maxScore, radius, stroke, strokeColor1, strokeColor2, text, stress}: CircularProgressResultProps) => {
    const [progress, setProgress] = useState(0);

    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = useMemo(() => {
        return circumference - (progress / 100) * circumference
    }, [circumference, progress]);

    useEffect(() => {
        if (currentScore === undefined || maxScore === undefined) {
          setProgress(0);
          //  setState('idle');
          //  setStartBreathing(false);
        } else if (typeof currentScore === 'number' && typeof maxScore  === 'number'){
            setProgress(Math.round((currentScore / maxScore) * 100.0));
            // setProgress(50)
        }
    }, [currentScore, maxScore]);

    return (
        <>
        {/* // v-if="startBreathing" */}
        <div className="d-flex justify-content-center" id="circular-progress-result"> 
            <div className="my-auto">
                <svg className="circle" height={(radius + stroke) * 2} width={(radius + stroke) * 2}>
                    <circle 
                        className="progress-result-background" 
                        stroke={strokeColor1}
                        fill="transparent"
                        strokeWidth={stroke} 
                        r={radius}
                        cx={radius + stroke} 
                        cy={radius + stroke} 
                        />
                    <circle
                        className="progress-result"
                        stroke={strokeColor2}
                        fill="transparent"
                        strokeDasharray={`${circumference} ${circumference}`} // 
                        style={{ strokeDashoffset }}
                        strokeWidth={stroke}
                        r={radius}
                        cx={radius + stroke}
                        cy={radius + stroke}
                        />
                {/* <text x="50%" y="50%" textAnchor="middle" dy=".3em">{timeLeft}</text> */}
                
                { stress && 
                <svg x={(radius + stroke) - 37 / 2} y={(radius + stroke) - 39 / 2}  width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0152 1.73633C26.5207 1.73633 30.9709 8.30224 31.8828 11.5852V18.1511C31.8828 20.8869 35.7129 22.5284 35.1658 24.717C34.728 26.4679 32.0652 26.5409 30.7885 26.3585V31.2829C30.6061 32.1949 29.4753 34.0187 26.4112 34.0187C23.3471 34.0187 20.0277 31.8301 18.751 30.7358V34.0187C18.751 36.2074 16.0152 37.3017 13.2794 37.3017C10.5436 37.3017 7.80781 36.7545 7.80781 34.0187V27.4528C5.80156 25.9937 1.78906 22.0906 1.78906 18.1511C1.78906 13.2267 2.88338 1.73633 16.0152 1.73633Z" stroke="#5F6368" strokeWidth="2.675" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.6377 29.0947H14.9207" stroke="#5F6368" strokeWidth="2.675" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.3735 20.8869C14.3735 22.5283 14.3735 24.1698 12.732 24.1698C11.0906 24.1698 10.5434 21.434 8.35477 19.7925C6.16614 18.1511 5.61898 15.9624 6.7133 12.6795C7.80762 9.39652 10.5434 5.56641 14.9207 5.56641C19.298 5.56641 23.6752 6.66072 26.411 11.5852C29.1468 16.5096 23.1281 15.9624 18.7508 15.9624C14.3735 15.9624 14.3735 19.2454 14.3735 20.8869Z" stroke="#5F6368" strokeWidth="2.675" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.6377 32.377H14.9207" stroke="#5F6368" strokeWidth="2.675" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="26.9581" cy="20.8866" r="1.64148" fill="#5F6368"/> 
                </svg> }
                {/* <!-- <circle className="breathing-circle-animation" 
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius"
                stroke="white"
                fill="white"
                /> --> */}
                </svg>
            </div>
        </div>
    </>
    )
}

export default CircularProgressResult;