export const ALL_LANGUAGE_CODES = ['en', 'zh-hant'] as const

export declare type LanguageCode = typeof ALL_LANGUAGE_CODES[number]

export declare type LocaleStrings = {
  LANGUAGE_CODE: LanguageCode,
  LANGUAGE_NAME: string,
  LANGUAGE_SHORT_NAME: string,
  LANDING_START_BUTTON_TITLE: string,
  LANDING_ELLIPSE_TITLE: string,
  LANDING_ELLIPSE_SUBTITLE: string,
  ANALYZING_RESULTS_TEXT: string,
  TERMS_CONDITIONS_PAGE_TITLE: string
  TERMS_CONDITIONS_PAGE_OPENING: string,
  TERMS_CONDITIONS_PAGE_CONTENT: string[],
  TERMS_CONDITIONS_PAGE_CLOSING: string,
  TERMS_CONDITIONS_PAGE_SIGNING: string,
  TERMS_CONDITIONS_PAGE_BUTTONS: {
    BACK: string,
    AGREE: string,
  },
  LOADING: string,
  SCANNING_CONDITIONS: {
    LIGHTING: string,
    DISTANCE: string,
    CENTERED: string,
    MOVEMENT: string,
    SERVER_READY: string,
  },
  SCANNING_PAGE_GO_BACK: string,
  FINAL_CHECK: string,
  CAMERA_PERMISSION_ERROR: string,
  ERROR_MODAL_BUTTON_OK: string,
  SCANNING_ERROR: {
    TITLE: string,
    FACE_LOST: string,
    OTHER: string,
    RETRY: string,
  },
  RESULTS_PAGE_VITALS: {
    HEALTH_SCORE: string,
    HEART_RATE: string,
    STRESS_LEVEL: string,
    BLOOD_PRESSURE: string,
    HEART_RATE_VARIABILITY: string,
    RESPIRATORY_RATE: string,
    OXYGEN_LEVEL: string,
  },
  // RESULTS_PAGE_STRESS_LEVELS: {
  //   POOR: string,
  //   GOOD: string,
  //   EXCELLENT: string,
  // }
  RESULTS_PAGE_POPUP: string,
  RESULTS_PAGE_BUTTONS: {
    RETRY: string,
    SAVE: string,
    SHARE: string,
    LUCKY_DRAW: string,
  },
  LUCKY_DRAW_TITLE: string,
  LUCKY_DRAW_DESCRIPTION: string,
  LUCKY_DRAW_DESCRIPTION_MOBILE: string,
  LUCKY_DRAW_INSTRUCTIONS_TITLE: string,
  LUCKY_DRAW_INSTRUCTIONS: {
    STEP_ONE_TITLE: string,
    STEP_ONE_DESCRIPTION: string,
    STEP_TWO_TITLE: string,
    STEP_TWO_DESCRIPTION: string,
    STEP_THREE_TITLE: string,
    STEP_THREE_DESCRIPTION: string,
  },
  LUCKY_DRAW_TC_NOTE: string,
  LUCKY_DRAW_FORM_TITLE: string,
  LUCKY_DRAW_FORM_LABELS: {
    NAME: string,
    HKID: string,
    PHONE: string,
    EMAIL: string,
    EMAIL_VALIDATION: string,
  },
  LUCKY_DRAW_FORM_ERRORS: {
    FULLNAME: string,
    HKID: string,
    PHONE: string,
    EMAIL: string,
    EMAILVALIDATION: string,
    NAME_ALREADY_REGISTERED: string,
    HKID_ALREADY_REGISTERED: string,
  },
  LUCKY_DRAW_FORM_PLACEHOLDERS: {
    FULLNAME: string,
    HKID: string,
    PHONE: string,
    EMAIL: string,
    EMAILVALIDATION: string,
  },
  LUCKY_DRAW_FORM_MISSING_FIELDS: {
    FULLNAME: string,
    HKID: string,
    PHONE: string,
    EMAIL: string,
    EMAILVALIDATION: string,
    CHECKBOX1: string,
    CHECKBOX2: string,
  },
  LUCKY_DRAW_USER_CONSENT: {
    CHECKBOX_ONE: string,
    CHECKBOX_TWO: string,
    PERSONAL_INFO_NOTE: string,
    CONFIRM_SUBMIT: string,
  },
  LUCKY_DRAW_BUTTONS: {
    DONE: string,
    BACK: string,
  },
  LUCKY_DRAW_SUBMITTED_PAGE_BUTTONS: {
    SHARE: string,
  }
  LUCKY_DRAW_SUBMITTED_PAGE_MESSAGE: string,
  LUCKY_DRAW_SUBMITTED_PAGE_BUTTON: string,
  LUCKY_DRAW_ALREADY_SUBMITTED: string,
  LUCKY_DRAW_TERMS_CONDITIONS_PAGE_TITLE: string,
  LUCKY_DRAW_TERMS_CONDITIONS_PAGE_CONTENT: string[],
  LUCKY_DRAW_WINNERS: {
    TITLE: string,
    TABLE_HEAD: string,
    TABLE_SUB_HEAD: string,
    TABLE_FOOTER: string
  },
}

export const LOCALE_STRINGS: { [index in LanguageCode]: LocaleStrings } = {
  'en': {
    LANGUAGE_CODE: 'en',
    LANGUAGE_NAME: 'English',
    LANGUAGE_SHORT_NAME: 'EN',
    LANDING_START_BUTTON_TITLE: 'START',
    LANDING_ELLIPSE_TITLE: 'Scan Your Health',
    LANDING_ELLIPSE_SUBTITLE: 'Your Stress Personality \nType',
    ANALYZING_RESULTS_TEXT: 'Loading your stress \npersonality...',
    TERMS_CONDITIONS_PAGE_TITLE: 'Terms of Use',
    TERMS_CONDITIONS_PAGE_OPENING: `You are now accessing the wellness monitoring solution (the “Solution”) powered by Panoptic AI Limited (“Panoptic AI”), a service provider of FWD Life Insurance Company (Bermuda) Limited ("FWD" or "We"). By using this Solution via FWD eServices, you acknowledge and agree to the below terms of use, as well as the explanations: `,
    TERMS_CONDITIONS_PAGE_CONTENT: [
      `FWD is not the service provider for the wellness monitoring solution. `,
      `The indicators that we deliver are based on the guidelines and standards at Panoptic AI and are exclusively for general wellness purposes, without any form of guarantee. We do not guarantee the availability, accuracy and completeness of the information provided by the Solution and the wellness data measured through this Solution in any circumstances.` ,
      `Any issues or concerns related to the wellness test should be directed to the medical professionals by users. FWD shall not be liable for any consequences arising from and consequential or incidental to the use of the Solution or any failure to seek appropriate professional advice.`,
      `The Solution is designed to scan the face using the built-in device’s camera of the participant / user to measure vital signs through a contactless method. These vital signs include wellness score, stress level, skin age, and blood pressure. Facial image and video footage will be captured through the camera on the user’s device for the purpose of wellness data calculation only, no facial image, video footage and wellness data regarding the vital signs will be collected and stored by FWD or Panoptic AI. `,
      `The Solution powered by Panoptic AI is not a medical device but a wellness monitoring solution. It is designed to provide information related to your wellness only, it should not be used as a substitute for professional medical advice, diagnosis, or treatment. It is the user’s responsibility to consult a registered healthcare professional or medical practitioner for any medical concerns or advice.  Users agree that any reliance on the wellness data generated by the Solution is at his/her own risk. `,
      `By using this Solution, you confirm that you are capable of conducting the test independently and do not belong to vulnerable groups, such as children of school age or individuals who are less capable of managing their own affairs. `,
      `By using this Solution and providing your personal information, you acknowledge and agree to accept the aforementioned (a) the <a target="_blank" href="https://panoptic.ai/end-user-licence-agreement/">T&C </a>, (b) <a target="_blank" href="https://panoptic.ai/personal-information-collection-statement/">PICS </a>, and (c) <a target="_blank" href="https://panoptic.ai/privacy-policy/">Privacy Policy </a> of PanopticAI.`,
    ],
    TERMS_CONDITIONS_PAGE_CLOSING: `By using the Solution, you acknowledge that you have read, understood, and agreed to the Terms of Use above. FWD assumes the Customer’s use of the Solution indicates due acceptance of this Term of Use. Please contact us if you have any questions or require further clarification. `,
    TERMS_CONDITIONS_PAGE_SIGNING: `FWD Life Insurance Company (Bermuda) Limited `,
    TERMS_CONDITIONS_PAGE_BUTTONS: {
      BACK: 'Back',
      AGREE: 'Agree',
    },
    LOADING: 'Loading...',
    SCANNING_CONDITIONS: {
      LIGHTING: "Lighting",
      DISTANCE: "Distance",
      CENTERED: "Centered",
      MOVEMENT: "Movement",
      SERVER_READY: "Server Ready"
    },
    SCANNING_PAGE_GO_BACK: 'Go back',
    FINAL_CHECK: `Assessment will start in {second} seconds.
    Please hold still.`,
    CAMERA_PERMISSION_ERROR: `Camera access is not allowed. Please refresh the page and grant camera permission.`,
    ERROR_MODAL_BUTTON_OK: 'OK',
    SCANNING_ERROR: {
      TITLE: 'Error',
      FACE_LOST: `Scanning aborted. Please make sure your face is positioned correctly.`,
      OTHER: `Something went wrong. Please try again.`,
      RETRY: 'Retry',
    },
    RESULTS_PAGE_VITALS: {
      HEALTH_SCORE: 'Health Score',
      HEART_RATE: 'Heart Rate',
      STRESS_LEVEL: 'Stress Level',
      BLOOD_PRESSURE: 'Blood Pressure',
      HEART_RATE_VARIABILITY: 'Heart Rate Variability',
      RESPIRATORY_RATE: 'Respiratory Rate',
      OXYGEN_LEVEL: 'Oxygen Level',
    },
    // RESULTS_PAGE_STRESS_LEVELS: {
    //   POOR: 'Poor',
    //   GOOD: 'Good',
    //   EXCELLENT: 'Excellent'
    // },
    RESULTS_PAGE_POPUP: 'Link copied!',
    RESULTS_PAGE_BUTTONS: {
      RETRY: 'Retry',
      SAVE: 'Save',
      SHARE: 'Share',
      LUCKY_DRAW: 'Lucky Draw Registration',
    },
    LUCKY_DRAW_TITLE: '"Scan Your Health" Lucky Draw',
    LUCKY_DRAW_DESCRIPTION: `
    Experience "Scan for Your Health: Your Stress-Type Personality" now and have a chance to win an iPhone 
    15 Pro Max or other fabulous prizes! 
    Campaign Period: From March 23, 2024 (12:00 AM) to May 18, 2024 (11:59 PM) 
    Trade Promotion Competition Licence Number: 58299`,
    LUCKY_DRAW_DESCRIPTION_MOBILE: `
    Experience "Scan for Your Health: Your Stress-Type Personality" now and have a chance to win an iPhone 15 Pro Max or other fabulous prizes! \n
    Campaign Period: From March 23, 2024 (12:00 AM) to May 18, 2024 (11:59 PM) \n
    Trade Promotion Competition Licence Number: 58299`,
    LUCKY_DRAW_INSTRUCTIONS_TITLE: 'How to Participate',
    LUCKY_DRAW_INSTRUCTIONS: {
      STEP_ONE_TITLE: 'Experience the AI Health Scan',
      STEP_ONE_DESCRIPTION: 'Participant aged 18 or above face the front camera of your device and remain still for approximately 30 seconds to conduct the facial scanning. Check the stress level of your personality and suggested relief tips.',
      STEP_TWO_TITLE: 'Register for “Scan Your Health” Lucky Draw',
      STEP_TWO_DESCRIPTION: 'Hong Kong resident aged 18 or above could enroll the lucky draw simply for filling out the registration form.',
      STEP_THREE_TITLE: 'Get a chance to win fabulous prizes',
      STEP_THREE_DESCRIPTION: 'The winner list will be announced on this event webpage, and the winners will receive a notification of their prize via the email address provided for this lucky draw registration.',
    },
    LUCKY_DRAW_TC_NOTE: 'Terms and Conditions',
    LUCKY_DRAW_FORM_TITLE: 'Lucky Draw Registration',
    LUCKY_DRAW_FORM_LABELS: {
      NAME: `Full name 
      (Same as Personal Identity Document)`,
      HKID: `HKID No. 
      (First letter(s) + first 3 digits)`,
      PHONE: 'Mobile No.',
      EMAIL: 'Email Address',
      EMAIL_VALIDATION: 'Confirm Email',
    },
    LUCKY_DRAW_FORM_ERRORS: {
      FULLNAME: 'Please enter a valid name.',
      HKID: 'Please enter a valid HKID no.',
      PHONE: 'Please enter a valid mobile no.',
      EMAIL: 'Please enter a valid email address.',
      EMAILVALIDATION: 'Emails do not match',
      NAME_ALREADY_REGISTERED: 'This name is already registered.',
      HKID_ALREADY_REGISTERED: 'This HKID is already registered.'
    },
    LUCKY_DRAW_FORM_PLACEHOLDERS: {
      FULLNAME: 'e.g. Chan Tai Man',
      HKID: 'e.g. A123',
      PHONE: 'e.g. 98765432',
      EMAIL: 'e.g. abc@example.com',
      EMAILVALIDATION: 'e.g. abc@example.com',
    },
    LUCKY_DRAW_FORM_MISSING_FIELDS: {
      FULLNAME: 'Full name is required',
      HKID: "HKID no. is required",
      PHONE: 'Phone no. is required',
      EMAIL: 'Email is required',
      EMAILVALIDATION: 'Email is required',
      CHECKBOX1: 'Checkbox must be checked',
      CHECKBOX2: 'Checkbox must be checked',
    },
    LUCKY_DRAW_USER_CONSENT: {
      CHECKBOX_ONE: `I confirm I have read, understood and agreed to the Terms & Conditions, and I agree to participating in the "Scan Your Health" Lucky Draw.`,
      // CHECKBOX_TWO: `I agree to the use of my personal information provided for direct marketing purposes.`,
      CHECKBOX_TWO: `I agree to use my personal data by FWD Life to receive marketing and promotional messages or information.`,
      PERSONAL_INFO_NOTE: `If you agree FWD Life Insurance Company (Bermuda) Limited to use and transfer your personal information in accordance with the paragraphs 9 of the <a target="_blank" href="https://www.fwd.com.hk/en/disclaimer/">Privacy Policy</a> and <a target="_blank" href="https://www.fwd.com.hk/en/privacy-policy/fwd-life-insurance-company/">Personal Information Collection Statement</a> for direct marketing purposes and receiving marketing communications, please tick (‘√') the box below.`,
      CONFIRM_SUBMIT: `By clicking "Confirm & Submit," you confirm that you have read, understood, and agreed to abide by the above terms and conditions.`,
    },
    LUCKY_DRAW_BUTTONS: {
      DONE: 'Confirm & Submit',
      BACK: 'Back',
    },
    LUCKY_DRAW_SUBMITTED_PAGE_MESSAGE: `Thank you for registering the lucky draw. \n The results of the draw will be announced on May 27, 2024, on this event webpage. \nGood luck!`,
    // Thank you for registering the lucky draw. The results of the draw will be
    // announced on April 8, 2024, on this event webpage.
    // Good luck!`,
    LUCKY_DRAW_SUBMITTED_PAGE_BUTTON: 'Back',
    LUCKY_DRAW_SUBMITTED_PAGE_BUTTONS: {
      SHARE: 'Invite your friend to join the lucky draw',
    },
    LUCKY_DRAW_ALREADY_SUBMITTED: 'Already submitted',
    LUCKY_DRAW_TERMS_CONDITIONS_PAGE_TITLE: `“Scan Your Health” Lucky Draw Terms and Conditions`,
    LUCKY_DRAW_TERMS_CONDITIONS_PAGE_CONTENT: [
      `Scan Your Health Lucky Draw (the "Campaign") is organised by FWD Life Insurance Company (Bermuda) Limited (“FWD Life”) (Incorporated in Bermuda with limited liability) in Hong Kong. By participating in this Campaign, participants are deemed to have read, accepted and agreed to be bound by the Campaign’s terms and conditions. `,
      `Registration for the Campaign starts at 00:00 on 23 Mar 2024 and ends at 23:59 on 18 May 2024 (the "Campaign Period"). The records in FWD Life's computer system are final. To stand a chance to participate in the Campaign and win the prize, participants shall complete all the registration requirements of the Campaign and agree the Terms of Use for “Scan Your Health” before the end time of the Campaign. `,
      `Eligibility: Participants must be at age of 18 years old or above and the holders of a valid Hong Kong ID Card.`,
      `Each participant is limited to one entry in the Campaign. If the information provided for participating in the Campaign (including, but not limited to, name, HKID first 4 digits, email address, and mobile number) is reused, incorrect, untrue, incomplete, or invalid, the participant's eligibility for the Campaign will be canceled automatically.`,
      `There will be 40 prize winners selected through a random draw from the list of eligible participants. Each participant is limited to one winning opportunity. The prizes include:
      <div class="tc-prizes">
        <p style="margin: 0">  First Prize: iPhone 15 Pro Max 512GB (1 winner)        </p>
        <p style="margin: 0">  Second Prize: Dyson Lightweight Cordless Vacuum Cleaner (1 winner) </p>
        <p style="margin: 0">  Third Prize: Dyson Pedestal Fan (1 winner) </p>
        <p style="margin: 0">  Fourth Prize: Sony WH-1000XM5 Headphones (1 winner) </p>
        <p style="margin: 0">  Fifth Prize: Hong Kong Express Airways HK$1,000 Cash Voucher (5 winners)    </p>
        <p style="margin: 0">  Sixth Prize: Hong Kong Ocean Park Standard Admission Tickets - 2 Adult Tickets (1 winner) </p>
        <p style="margin: 0">  Seventh Prize: PARKnSHOP HK$50 Coupon (30 winners) </p>
      </div>`,
      `Winners in this Campaign will be drawn randomly by the computer system of FWD Life. Participants will know the lucky draw result on 27 May 2024. The lucky draw result will be shown on the Campaign website and winners will be notified separately via their registered email address on the same day. The lucky draw result will also be posted at Sing Tao Daily and The Standard on 27 May 2024.`,
      `If the personal information provided by a winner is incorrect, untrue, unclear, or incomplete, resulting in FWD Life unable to contact the winner and/or verify and confirm their identity by 27 May 2024, the winner's eligibility will be canceled automatically. `,
      `Participants and winners must meet all applicable requirements stated in clause 3 to be eligible to enter this Campaign and win a prize. Otherwise, their eligibility for this Campaign and the prize will be disqualified without further notice.  FWD Life has the sole and absolute discretion in determining the eligibility of any participant and winner in accordance with these terms and conditions for this Campaign, and award the prize to an alternate winner. FWD Life reserves the right to verify the identity of the winner(s) and replace any winner subsequently found to be disqualified after the publication of the results of the lucky draw under this Campaign with alternative winner.`,
      `The personal information provided by participants will be used by FWD Life for the purposes of publicizing, contacting, verifying the identity of winners, and for these purposes, FWD Life may transfer and disclose the participant's data to other data processors or service providers located in Hong Kong. Participants have the right to request correction (if appropriate) of their personal data regarding the lucky draw registration. Requests for information amendment can be submitted via email to <a href="mailto:dp.hk@fwd.com">dp.hk@fwd.com </a>by 18 May 2024. `,
      `Participants agree to FWD Life’s <a target="_blank" href="https://www.fwd.com.hk/en/disclaimer/">Privacy Policy</a> and <a target="_blank" href="https://www.fwd.com.hk/en/privacy-policy/fwd-life-insurance-company/">Personal Information Collection Statement</a>, and agree to FWD Life using the personal data submitted in the lucky registration form for purposes related to this promotion; and agree to FWD Life using and transferring participants data in accordance with paragraph 9 of the Personal Information Collection Statement for direct marketing purposes, and receiving promotional messages or information.`,
      `The prize cannot be exchanged, transferred, returned or redeemed for cash, other products or services under all circumstances. `,
      `If the participants violate any rules of the Campaign or commit any illegal acts that result in any losses on the part of FWD Life or any third parties, the concerned and relevant participants will be liable for all liabilities. `,
      `FWD Life will not take any responsibility and will not re-send or replace the prize if it is lost, damaged or smeared. Winners will be advised on how to collect the prize, and FWD Life accepts no responsibility in relation to any arrangement made by winners for collecting the prize and reserves the right to cancel any uncollected prize without prior notice. `,
      `FWD Life accepts no responsibility for any late, lost, wrong or unidentifiable registration due to malfunction of any computer, smartphone or internet network and the participants will be regarded as failing to complete the registration process under these situations. `,
      `FWD Life reserves the right to disqualify any participants without prior notice if the participants are found to use plugins, empty or fake accounts or improper practice violating these Terms and Conditions to participate in the Campaign. `,
      `All intellectual property rights in relation to all items of this Campaign (including, but not limited to copyright, trademark, design and model rights) and other rights (including but not limited to banners, advertising property and concepts rights) shall belong to FWD Life or its legal owner(s). `,
      `FWD Life is not the supplier of the prizes and therefore will not assume any responsibility related to them. Any disputes regarding the prizes including the quality, usage or function of the prize received must be resolved directly between the winners and the prize suppliers. `,
      `FWD Life reserves the right to interpret these Terms and Conditions. In case of any disputes, FWD Life’s decisions are final and binding. `,
      `If there is any conflict between the information contained in the promotional materials and these Terms and Conditions, these terms and conditions shall apply and prevail and final. `,
      `In the event of discrepancies in the Terms and Conditions between the English and the Chinese version, the English version shall prevail. `,
      `Trade Promotion Competition Licence Number: 58299`,
    ],
    LUCKY_DRAW_WINNERS: {
      TITLE: '"Scan Your Health" Lucky Draw Result',
      TABLE_HEAD: 'FWD “Scan Your Health” Lucky Draw Result Announcement',
      TABLE_SUB_HEAD: 'The winners are as below:',
      TABLE_FOOTER: '“Scan Your Health” Lucky Draw was closed on 18 May 2024. Thank you for your support. Winners will be notified via their registered email address. Trade Promotion Competition Licence Number: 58299'
      },
  },
  'zh-hant': {
    LANGUAGE_CODE: 'zh-hant',
    LANGUAGE_NAME: '繁體中文',
    LANGUAGE_SHORT_NAME: '中',
    LANDING_START_BUTTON_TITLE: '開始測試',
    LANDING_ELLIPSE_TITLE: '掃描您健康:',
    LANDING_ELLIPSE_SUBTITLE: '您的壓力型人格',
    ANALYZING_RESULTS_TEXT: '正在加載 \n您的壓力性格...',
    TERMS_CONDITIONS_PAGE_TITLE: '使用條款',
    TERMS_CONDITIONS_PAGE_OPENING:`您正在存取由全境智能有限公司（以下簡稱“全境智能”）提供的健康測試程式（以下簡稱「程式」），該公司是富衛人壽保險（百慕達）有限公司（以下簡稱「富衛」或「我們」）的服務提供商。若您使用此程式，表示您已接受並同意遵守以下使用條款，以及相關說明：`,
    TERMS_CONDITIONS_PAGE_CONTENT: [
      `富衛並非健康測試程式的服務提供者。`,
      `我們提供的指標是基於Panoptic AI的準則和標準，僅供一般健康目的使用。在任何情況下，富衛不保證內容提供的資訊和由此程式測量的健康數據之可用性、準確性和完整性。`,
      `與健康測試結果相關的任何問題或疑慮應由用戶直接向醫療專業人員諮詢。富衛對因使用程式或未尋求適當的專業建議而導致的任何後果概不負責。 `,
      `此程式內的健康測試功能是透過裝置的相機鏡頭對臉部進行掃描，以非接觸式方法測量生命體徵，包括：綜合健康、壓力指數、皮膚年齡及血壓。用戶裝置上的相機鏡頭所拍攝的臉部影像和影片僅作健康數據計算之用，富衛 或 全境智能有限公司並不會收集或儲存任何臉部影像、影片片段及任何生命體徵的數據。 `,
      `此程式為 Panoptic AI提供支援的健康測試，而並非醫療設備。它僅設計用於提供與您的健康有關的信息，不應被用作專業醫療建議、診斷或治療的替代品。用戶有責任就任何醫療疑慮或建議諮詢註冊醫療專業人員或醫生。用戶同意自行承擔因依賴程式生成的健康數據而產生的風險。`,
      `若使用此程式，即表示您確認您有能力獨立進行測試，並且不屬於弱勢群體，例如學齡兒童或自理能力較低的人士。 `,
      `若使用此程式，即表示您確認已閱讀、理解並同意全境智能的（a）<a target="_blank" href="https://panoptic.ai/%E7%B5%82%E7%AB%AF%E7%94%A8%E6%88%B6%E8%A8%B1%E5%8F%AF%E5%8D%94%E8%AD%B0/">使用條款及細則</a>， (b) <a target="_blank" href="https://panoptic.ai/%E5%80%8B%E4%BA%BA%E8%B3%87%E6%96%99%E6%94%B6%E9%9B%86%E8%81%B2%E6%98%8E/">個人資料收集聲明（PICS） </a> ，及（c）<a target="_blank" href="https://panoptic.ai/%E7%A7%81%E9%9A%B1%E6%94%BF%E7%AD%96/">私隱政策</a>當中所列出之條款規範。 `,
    ],
    TERMS_CONDITIONS_PAGE_CLOSING: `通過使用此程式，即表示您確認已閱讀、理解並同意上述使用條款。富衛認為您使用此程式即表示接受本使用條款。如果您有任何問題或需要進一步澄清，請聯繫我們。`,
    TERMS_CONDITIONS_PAGE_SIGNING: `富衛人壽保險（百慕達）有限公司 `,
    TERMS_CONDITIONS_PAGE_BUTTONS: {
      BACK: '返回',
      AGREE: '我已閱讀並同意',
    },
    LOADING: '加載中...',
    SCANNING_CONDITIONS: {
      LIGHTING: "燈光",
      DISTANCE: "距離",
      CENTERED: "置中",
      MOVEMENT: "靜止",
      SERVER_READY: "系統準備就緒"
    },
    SCANNING_PAGE_GO_BACK: '返回',
    FINAL_CHECK: '掃描將在 {second} 秒後開始。 請保持靜止。',
    CAMERA_PERMISSION_ERROR: '未能啓動相機。重新整理頁面及授予相機權限。',
    ERROR_MODAL_BUTTON_OK: '好的',
    SCANNING_ERROR: {
      TITLE: '錯誤',
      FACE_LOST: `未能繼續掃描。請保持面容位置正確`,
      OTHER: '發生了問題，請重試一次。',
      RETRY: '重試'
    },
    RESULTS_PAGE_VITALS: {
      HEALTH_SCORE: '綜合健康',
      HEART_RATE: '心率',
      STRESS_LEVEL: '壓力指數',
      BLOOD_PRESSURE: '血壓',
      HEART_RATE_VARIABILITY: '心率變異性',
      RESPIRATORY_RATE: '呼吸頻率',
      OXYGEN_LEVEL: '血氧飽和度',
    },
    // RESULTS_PAGE_STRESS_LEVELS: {
    //   POOR: '欠佳',
    //   GOOD: '良好',
    //   EXCELLENT: '優秀'
    // },
    RESULTS_PAGE_POPUP: '連結已複製！',
    RESULTS_PAGE_BUTTONS: {
      RETRY: '重試一次',
      SAVE: '保存結果',
      SHARE: '分享連結',
      LUCKY_DRAW: '登記抽獎',
    },
    LUCKY_DRAW_TITLE: '「掃描您健康」大抽獎',
    LUCKY_DRAW_DESCRIPTION: `
    立即體驗 “掃描您健康: 您的壓力型人格”並參加抽獎就有機會贏取iPhone 15 Pro Max一部, 或其他豐富獎品！
    活動日期: 2024年3月23日（凌晨12時）至 2024年5月18日（晚上11時59分）
    推廣生意的競賽牌照號碼：58299`,
    LUCKY_DRAW_DESCRIPTION_MOBILE:  `
    立即體驗 “掃描您健康: 您的壓力型人格”並參加抽獎就有機會贏取iPhone 15 Pro Max一部, 或其他豐富獎品！
    活動日期: 2024年3月23日（凌晨12時）至 2024年5月18日（晚上11時59分）
    推廣生意的競賽牌照號碼：58299`,
    LUCKY_DRAW_INSTRUCTIONS_TITLE: '如何參加',
    LUCKY_DRAW_INSTRUCTIONS: {
      STEP_ONE_TITLE: '立即體驗AI健康掃瞄',
      STEP_ONE_DESCRIPTION: '年滿18歲的參加者需面向裝置的前置鏡頭並靜止約 30 秒以進行面部掃描，並查閱您的壓力型人格和建議減壓方法。',
      STEP_TWO_TITLE: '登記參加「掃描您健康」大抽獎',
      STEP_TWO_DESCRIPTION: '凡年滿18歲之香港居民只需填妥登記表格即可參加本抽獎活動。',
      STEP_THREE_TITLE: '獲得抽獎機會以贏取豐富獎品',
      STEP_THREE_DESCRIPTION: '得獎名單將於本活動網頁內公佈，並根據登記抽獎時之電郵地址向得獎者發出得獎通知。',
    },
    LUCKY_DRAW_TC_NOTE: `條款及細則`,
    LUCKY_DRAW_FORM_TITLE: '登記抽獎',
    LUCKY_DRAW_FORM_LABELS: {
      NAME: `姓名 
      (須與身份證明文件相同)`,
      HKID: `香港身分證號碼
      (英文字母加首三位數字)`,
      PHONE: '手機號碼',
      EMAIL: '電郵地址',
      EMAIL_VALIDATION: '確認電郵',
    },
    LUCKY_DRAW_FORM_PLACEHOLDERS: {
      FULLNAME: '例如：陳大文',
      HKID: '例如：A123',
      PHONE: '例如：98765432',
      EMAIL: '例如：abc@example.com',
      EMAILVALIDATION: '例如：abc@example.com',
    },
    LUCKY_DRAW_FORM_MISSING_FIELDS: {
      FULLNAME: '請輸入全名',
      HKID: '請輸入香港身分證號碼',
      PHONE: '請輸入手機號碼',
      EMAIL: '請輸入電郵地址',
      EMAILVALIDATION: '請輸入電郵地址',
      CHECKBOX1: '必須選取複選框',
      CHECKBOX2: '必須選取複選框'
    },
    LUCKY_DRAW_FORM_ERRORS: {
      FULLNAME: '請輸入有效的名稱。',
      HKID: '請輸入有效的香港身分證號碼。',
      PHONE: '請輸入有效的手機號碼。',
      EMAIL: '請輸入有效的電郵地址。',
      EMAILVALIDATION: '請輸入有效的電郵地址。',
      NAME_ALREADY_REGISTERED: '此名字已被登記。',
      HKID_ALREADY_REGISTERED: '此香港身分證號碼已被登記。',
    },
    LUCKY_DRAW_USER_CONSENT: {
      CHECKBOX_ONE: `我已細讀及同意以上條款及細則，並同意參加「掃描您健康」大抽獎。`,
      // CHECKBOX_TWO: `我同意使用我所提供的個人資料進行直接促銷活動。`,
      CHECKBOX_TWO: `我同意本人的個人資料予富衛人壽作為接收推廣訊息或資料。`,
      PERSONAL_INFO_NOTE: `如若您同意富衛人壽保險（百慕達）有限公司根據<a target="_blank" href="https://www.fwd.com.hk/zh/disclaimer/">私隱政策</a>及<a target="_blank" href="https://www.fwd.com.hk/zh/privacy-policy/fwd-life-insurance-company/">收集個人資料聲明</a>第9項的規定，使用和轉移您的個人資訊進行直接促銷用途以及接收推廣訊息或資料，請在下面的方框中打剔（'√' )。`,
      CONFIRM_SUBMIT:  `如閣下選擇「確定並提交」即表示閣下確定已閱讀、接受及同意遵守以上條款及細則。`
    },
    LUCKY_DRAW_BUTTONS: {
      DONE: '確定並提交',
      BACK: '返回',
    },
    LUCKY_DRAW_SUBMITTED_PAGE_BUTTONS: {
      SHARE: '邀請朋友參加抽獎',
    },
    LUCKY_DRAW_SUBMITTED_PAGE_MESSAGE: `感謝您登記抽獎，抽獎結果將在2024年5月27日於本活動網頁內公佈。
    祝你好運！`,
    LUCKY_DRAW_SUBMITTED_PAGE_BUTTON: '返回',
    LUCKY_DRAW_ALREADY_SUBMITTED: '已經提交',
    LUCKY_DRAW_TERMS_CONDITIONS_PAGE_TITLE: `「掃描您健康」大抽獎條款及細則 `,
    LUCKY_DRAW_TERMS_CONDITIONS_PAGE_CONTENT: [
      `掃描您健康大抽獎（「本活動」）由富衛人壽保險（百慕達）有限公司（「富衛人壽」）（於百慕達註冊成立之有限公司）主辦。參加者登記參加本活動時，即表示參加者已閱讀、接受及同意遵守本活動的條款及細則。 `,
      `本活動的登記時間將由2024年3月23日（凌晨12時）開始，並於 2024年5月18日（晚上11時59分）結束（「活動期間」）。富衛人壽之電腦系統中的記錄為最終結果。參加者必須於活動結束前完成所有參加本活動之登記要求並同意其條款及細則及同意掃描您健康的使用條款方可獲得參與抽獎機會。 `,
      `參加資格: 參加者必須年滿18歲或以上，並持有有效香港身份證。 `,
      `每位參加者只限參加本抽獎活動1次。如為參加本抽獎活動所提供的所需資料（包括但不限於姓名、身份證號碼首四個字、電郵地址、手機號碼）重覆使用、不正確、不真實、不完整或無效，參加者的抽獎資格將會被自動取消。 `,
      `富衛人壽將從合資格參與者名單中隨機抽取40名得獎者。每名參加者僅限獲得一次中獎機會。獎品包括::
      <div class="tc-prizes">
        <p style="margin: 0"> 頭獎: iPhone 15 Pro Max 512GB (1名)  </p>
        <p style="margin: 0"> 二獎: Dyson 輕量無線吸塵機 (1名)  </p>
        <p style="margin: 0"> 三獎: Dyson 座地式風扇 (1名) </p>
        <p style="margin: 0"> 四等獎: Sony WH-1000XM5 耳機 (1名)  </p>
        <p style="margin: 0"> 五等獎: 香港快運航空HK$1,000 現金券 (5名) </p>
        <p style="margin: 0"> 六等獎: 香港海洋公園標準入場門票 - 成人2張 (1名)   </p>
        <p style="margin: 0"> 七等獎: 百佳 $50 現金券 (30 名) </p>
      </div>
      `,
      `得獎者將由富衛人壽的電腦系統隨機抽取。參加者將於2024年5月27日得知抽獎結果。抽獎結果將會於本活動網頁內公佈及根據參加者登記之電郵地址向得獎者同一天發出得獎通知。抽獎結果也將於2024年5月27日刊登在《星島日報》和《英文虎報》上。 `,
      `如參加者提供的個人資訊不正確、虛假、不清楚或不完整，導致富衛人壽無法在2024年5月27日之前與得獎者聯繫、驗證和核實其身份，得獎者的資格則將自動取消。 `,
      `參加者及得獎者須符合第3項列明所有適用之要求方可合資格參加此活動及得獎，否則其參加及得獎資格將會被取消而不作另行通知。富衛人壽有權根據此條款和細則來決定參加者及得獎者資格，並將獎品頒發給替補得獎者。富衛人壽保留在抽獎結果公佈後再次核實得獎者身份的權利，並有權以替補得獎者取代任何不符資格的得獎者。`,
      `參加者提供的個人資料將由富衛人壽用於宣傳、聯繫、核實得獎者身份等目的。富衛人壽可能將參加者的資料轉移和披露給位於香港的其他數據處理者或服務提供商。參加者有權要求更正（如適用）有關抽獎登記的個人資料。申請須於2024年5月18日或之前通過電子郵件<a href="mailto:dp.hk@fwd.com">dp.hk@fwd.com </a> 提交。 `,
      `參加者同意富衛人壽的<a target="_blank" href="https://www.fwd.com.hk/zh/disclaimer/">私隱政策</a>及<a target="_blank" href="https://www.fwd.com.hk/zh/privacy-policy/fwd-life-insurance-company/">收集個人資料聲明</a>及同意富衛人壽使用參加者在登記抽獎表格所提交之個人資料以處理與此推廣有關之用途; 及同意富衛人壽根據收集個人資料聲明第9項使用及轉移參加者的個人資料作為直接促銷用途，及接收推廣訊息或資料。`,
      `在任何情況下，該獎品均不可更換、轉讓、退回或兌換現金、其他產品或服務。 `,
      `如參加者因違反活動的任何規則或從事任何非法行為導致富衛人壽或任何第三方遭受損失，有關參加者須負上一切法律責任。 `,
      `獎品如遺失、損壞或塗污，富衛人壽概不補發或更換，亦不承擔任何責任。得獎者將獲告知如何領取獎品，富衛人壽對獲獎者領取獎品所做的任何安排不承擔任何責任，並保留取消任何未領取獎品的權利，恕不另行通知。 `,
      `任何因電腦、智能電話、網絡技術問題而引致參加者的登記有所延誤、遺失、錯誤、無法辨識等情況，參加者將被視為未能完成登記本活動，而富衛人壽概不對此負上任何責任。 `,
      `如發現參加者以外掛程式、蓄意以不正當手法、空號或假帳戶，或其他違反本條款及細則的不誠實方法，富衛人壽有權取消其參加及得奬資格而不作任何通知。 `,
      `關於本活動所有物品的所有知識產權（包括但不限於版權、商標權、外觀設計及模型權）及其他權利（包括但不限於標語、廣告資產及概念的權利）均歸屬於富衛人壽 或其合法擁有人。 `,
      `富衛人壽並非該獎品之供應商，故此不會承擔與之相關的任何責任。一切有關該獎品的品質、使用或功能的任何爭議，須由得奬者直接與獎品供應商自行解決。 `,
      `富衛人壽對本條款及細則擁有絕對之解釋權。如有任何爭議，概以富衛人壽的決定為最終且具有約束力。 `,
      `如宣傳物品所載之資料與本條款及細則有任何衝突，概以本條款及細則為準。 `,
      `如此條款及細則之中文及英文版本存有歧異，應以英文版本為準。 `,
      `推廣生意的競賽牌照號碼：58299 `,
    ],
    LUCKY_DRAW_WINNERS: {
      TITLE: '「掃描您健康」大抽獎結果',
      TABLE_HEAD: 'FWD 「掃描您健康」大抽獎結果公佈',
      TABLE_SUB_HEAD: '抽獎結果如下︰',
      TABLE_FOOTER: '「掃描您健康」大抽獎登記期已於2024年5月18日結束，多謝支持。我們將根據得獎者參加抽獎登記時所提供的電郵地址，以電郵形式發送得獎通知。推廣生意的競賽牌照號碼：58299'
    },
    },
}
