import { useUserContext } from "./UserProvider";
import { writeToS3Bucket } from "../s3bucket/S3Bucket";
import debounce from 'lodash-es/debounce';
import { useCallback, useEffect, useState } from "react";

export enum PageId {
  Landing = 'LANDING_PAGE',
  Scanning = 'SCNNING_PAGE',
  Result = 'RESULT_PAGE',
  LuckyDraw = 'LUCKY_DRAW_PAGE',
  TermsConditions = 'TERMS_CONDITIONS_PAGE'
}

export enum ActionId {
  StartScaning = 'START_SCANNING'
}

export function pageLog(userId: string, pageId:PageId) {
  console.log('[Logger] PageLog: ', pageId)
  const timestamp = `${new Date().valueOf()}`
  writeToS3Bucket(`log/${userId}/${timestamp}.json`, {pageId})
}

export function actionLog(userId: string, pageId:PageId, actionId:ActionId) {
  console.log('[Logger] ActionLog: ', pageId, actionId)
  const timestamp = `${new Date().valueOf()}`
  writeToS3Bucket(`log/${userId}/${timestamp}.json`, {pageId, actionId})
}

export function useLogger(pageId:PageId) {

  const ctx = useUserContext()
  const [logger, setLogger] = useState({logAction:(actionId:ActionId)=>{}, logPage:()=>{}})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logPage = useCallback(debounce(()=>{
    if (!ctx.user) {
      return
    }
    pageLog(ctx.user.userId, pageId)
  }, 300), [])

  const logAction = useCallback((actionId:ActionId) => {
    if (!ctx.user) {
      return
    }
    actionLog(ctx.user.userId, pageId, actionId)
  }, [ctx.user, pageId])

  useEffect(()=>{

    setLogger({ logAction, logPage })

  }, [ctx.user, logPage, logAction, pageId])

  useEffect(()=>{logger.logPage()}, [logger])

  return logger
}