import "bootstrap/dist/css/bootstrap.min.css";
import "./CircularProgress.css";
import { useState, useEffect, useMemo } from 'react';

type CircularProgressProps = {
    timeLeft: number | undefined; // default: null
    radius: number;
    stroke: number;
    text: string;
}

const CircularProgress = ({ timeLeft, radius, stroke, text }: CircularProgressProps) => {
    const [progress, setProgress] = useState(0);
    const totalTime = 25;

    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = useMemo(() => {
        return circumference - (progress / 100) * circumference
    }, [circumference, progress]);

    useEffect(() => {
        if (timeLeft === undefined) {
          setProgress(0);
          // setState('idle');
          // setStartBreathing(false);
        } else if (typeof timeLeft === 'number'){
            setProgress(Math.round(((totalTime - timeLeft) / totalTime) * 100.0));
            // setProgress(50)
        }
    }, [timeLeft]);

    return (
        <>
        {/* // v-if="startBreathing" */}
        <div className="d-flex justify-content-center" id="circular-progress"> 
            <div className="my-auto">
                <svg className="circle" height={(radius + stroke) * 2} width={(radius + stroke) * 2}>
                    <circle 
                        className="progress-background" 
                        stroke="#E87722"
                        fill="transparent"
                        strokeWidth={stroke} 
                        r={radius}
                        cx={radius + stroke} 
                        cy={radius + stroke} 
                        />
                    <circle
                        className="progress"
                        stroke="#E87722"
                        fill="transparent"
                        strokeDasharray={`${circumference} ${circumference}`} // 
                        style={{ strokeDashoffset }}
                        strokeWidth={stroke}
                        r={radius}
                        cx={radius + stroke}
                        cy={radius + stroke}
                        />
                <text x="50%" y="50%" textAnchor="middle" dy=".3em">{progress}%</text>
                {/* <!-- <circle className="breathing-circle-animation" 
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius"
                stroke="white"
                fill="white"
                /> --> */}
                </svg>
            </div>
        </div>
    </>
    )
}

export default CircularProgress;