import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../../common/user/UserProvider';
import { Health } from 'react-vital-sign-camera';
import { useLogger, PageId } from '../../common/user/Logger';
import { useShareController } from '../../common/share/Share';
import { useLocale } from '../../common/locale/LocaleProvider';
import LanguagePicker from '../../common/components/LanguagePicker';

import "bootstrap/dist/css/bootstrap.min.css";
import './HealthResult.css';

import CircularProgressResult from './CircularProgressResult';
import bullEng from "./assets/bull-eng.png";
import bullTc from "./assets/bull-tc.png";
import bullEngMobile from "./assets/bull-eng-mobile.png";
import bullTcMobile from "./assets/bull-tc-mobile.png";
import capybaraEng from "./assets/capybara-eng.png";
import capybaraTc from "./assets/capybara-tc.png";
import capybaraEngMobile from "./assets/capybara-eng-mobile.png";
import capybaraTcMobile from "./assets/capybara-tc-mobile.png";
import lionEng from "./assets/lion-eng.svg";
import lionTc from "./assets/lion-tc.png";
import lionEngMobile from "./assets/lion-eng-mobile.png";
import lionTcMobile from "./assets/lion-tc-mobile.png";
import penguinEng from "./assets/penguin-eng.png";
import penguinTc from "./assets/penguin-tc.png";
import penguinEngMobile from "./assets/penguin-eng-mobile.png";
import penguinTcMobile from "./assets/penguin-tc-mobile.png";
import rabbitEng from "./assets/rabbit-eng.png";
import rabbitEngMobile from "./assets/rabbit-eng-mobile.png";
import rabbitTc from "./assets/rabbit-tc.png";
import rabbitTcMobile from "./assets/rabbit-tc-mobile.png";

import variationIcon from "./assets/variation.svg";
import bloodPressureIcon from "./assets/blood-pressure.svg";

import resetButtonIcon from "./assets/reset.svg";
import shareButtonIcon from "./assets/share.svg";
import saveButtonIcon from "./assets/download.svg";
import { useLanguageFromParam } from '../../common/locale/useLanguageFromParam';
import { UserValidator } from '../../common/user/UserValidator';
import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';

const loadHealthFromSession = () => {
  const healthJSON = sessionStorage.getItem('healthJSON')
  return healthJSON ? JSON.parse(healthJSON) as Health : undefined
}

const saveHealthToSession = (health:Health|undefined) => {
  if (health === undefined) {
    return
  }
  sessionStorage.setItem('healthJSON', JSON.stringify(health))
}

const VitalSign = (props: { name: string; value: number | string; icon?: string; label: string; stress?: number, units?: string }) => {
  let value = props.value;
  if (props.value === "Error") {
    value = '...';
  }
  if (typeof value === 'number') {
    // value = value.toFixed(1); //converts number to string
    value = parseFloat(value.toFixed(1)).toString(); // removes trailing .0
  }
  if (typeof value !== 'number' && typeof value !== 'string') {
    value = `Invalid Value: ${JSON.stringify(value)}`;
  }

  return (
    <div className="square col-6 col-md-2 p-0 h-100 vitalSignContainer">
      <div className="square">
        <div className="row position-relative h-25 p-3">
          <p className="vitals-label">{props.label}</p>
        </div>

        <div className="row position-relative h-50 p-0">
          {props.icon && <img src={props.icon} alt="vitals-icon" className={`${props.name}-icon mx-auto z-0 p-0 align-self-center`} /> }
          {props.name==="general-wellness" &&
            <div className={`${props.name}-icon mx-auto z-0 p-0 align-self-center`}>
              <CircularProgressResult
                currentScore={parseFloat(value)}
                maxScore={100}
                radius={46.2}
                stroke={10}
                strokeColor1={"#CED8D6"}
                strokeColor2={"#E87722"}
                text=""
                stress={false}
              />
            </div>}
          {props.stress &&
            <div className={`${props.name}-icon mx-auto z-0 p-0 align-self-center`}>
              <CircularProgressResult
                currentScore={parseFloat(value)}
                maxScore={5}
                radius={36.3}
                stroke={10}
                strokeColor1={"#CED8D6"}
                strokeColor2={"#E87722"}
                text=""  // change
                stress={true}
              />
            </div>}
          {props.name==="oxygen" &&
            <div className={`${props.name}-icon mx-auto z-0 p-0 align-self-center`}>
              <CircularProgressResult
                currentScore={parseFloat(value)}
                maxScore={100}
                radius={46.2}
                stroke={10}
                strokeColor1={"#CED8D6"}
                strokeColor2={"#E87722"}
                text=""
                stress={false}
                />
            </div>}
        </div>

        <div className="row position-relative h-25">
          <p>
            <span className={`${props.name} text-left`}>
              {/* {props.name === "stress" ? props.stress || '' : (props.name === "oxygen" ? `${value}%` : value)} */}
              {props.name === "stress" ? `${value}/5` || '' : (props.name === "oxygen" ? `${value}%` : value)}
            </span>

            {(props.name === "heart-rate" || props.name === "blood-pressure" || props.name === "heart-rate-variability" || props.name === "respiratory-rate")
            && <span className="units">{` ${props.units}`}</span>}
          </p>
        </div>
      </div>
    </div>
  )
}

const HealthResult = () => {
  const { locale } = useLocale();
  const { strings } = locale;

  const language = locale.strings.LANGUAGE_CODE;
  // const { logAction } = useLogger(PageId.Landing)

  const navigate = useNavigate();
  const userCtx = useUserContext();
  const [health, setHealth] = useState<Health|undefined>();
  const result = useRef(null);

  useLogger(PageId.Result)

  const location = useLocation();
  const {copiedPopupShow, share} = useShareController();
  let [displayButtons, setDisplayButtons] = useState(true);
  const [showLuckyDrawButton, setShowLuckyDrawButton] = useState(false);

  useLanguageFromParam()

  // useEffect(() => {
  //   const getCurrentDate = () => {
  //     let currentDate = new Date();
  //     let endDate =  new Date(2024, 4, 19, 0, 0, 0); // 19/5/2024 00:00
  //     // let endDate =  new Date(2024, 4, 17, 11, 10, 0); // testing

  //     // console.log("currentDate", currentDate)

  //     if (currentDate >= endDate) {
  //         setShowLuckyDrawButton(false);
  //         // console.log("hide lucky draw button")
  //       } else {
  //         setShowLuckyDrawButton(true)
  //         // console.log("show lucky draw button")
  //       }
  //   };

  //   getCurrentDate(); // when HealthResult component mounts

  //   const dateCheckInterval = setInterval(getCurrentDate, 60000) // every minute

  //   return () => clearInterval(dateCheckInterval) // clean up when HealtHResult component unmounts
  // }, []);

  useEffect(() => {
    const base64Health = new URLSearchParams(location.search).get('health');

    let parsedHealthParam;
    if (base64Health) {
      try {
        parsedHealthParam = JSON.parse(atob(base64Health));
        setDisplayButtons(false)
      } catch (error) {
        console.log("error parsing health data from param: ", error);
      }
    }

    const health = parsedHealthParam || userCtx.user?.health || loadHealthFromSession()
    saveHealthToSession(health)
    setHealth(health)
  }, [])

  if (health === undefined || !health.vitalSigns || !health.holisticHealth || !health) {
    return <></>
  }

  const vs = health.vitalSigns;
  const holistic = health.holisticHealth;
  const cardio = health.risks?.cardiovascularRisks;
  const covid = health.risks?.covidRisk;

  if (!vs || !holistic || !covid) {
    return <>Error: Incomplete Result. ({`${!vs} ${!holistic} ${!cardio} ${!covid}`})</>
  }

  const back = () => {
    navigate('/landing')
  }

  const save = async () => {
    if (!result.current) {
      return
    }
    const canvas = await html2canvas(result.current, { windowWidth: 640 })
    canvas.toBlob(blob => {
      if (!blob) {
        return
      }
      downloadjs(blob, 'result.png', 'image/png')
    }, 'png')
  }

  const luckyDraw = () => {
    navigate('/luckyDraw')
  }

  // const getStressRating = () => {
    // let stressRating = '';
    // if(vs.stress && vs.stress >= 3) {
    //   stressRating = strings.RESULTS_PAGE_STRESS_LEVELS.POOR
    // } else if (vs.stress && vs.stress >= 2 && vs.stress < 3) {
    //   stressRating = strings.RESULTS_PAGE_STRESS_LEVELS.GOOD
    // } else if (vs.stress && vs.stress >= 0 && vs.stress < 2) {
    //   stressRating = strings.RESULTS_PAGE_STRESS_LEVELS.EXCELLENT
    // }
    // return stressRating;
  // }

  const getAnimalImage = (isMobile: boolean, language: string) => {
    let animalImage = '';
    let animalMobileImage = '';

    if (!vs.stress) {
      return undefined;
    }

    let stress = parseFloat(vs.stress.toFixed(1));

    if (stress <= 1) {
      animalImage = language === 'en' ? capybaraEng : capybaraTc;
      animalMobileImage = language === 'en' ? capybaraEngMobile : capybaraTcMobile;
    } else if (stress >= 1.1 && stress <= 1.5) {
      animalImage = language === 'en' ? penguinEng : penguinTc;
      animalMobileImage = language === 'en' ? penguinEngMobile : penguinTcMobile;
    } else if (stress >= 1.6 && stress <= 2) {
      animalImage = language === 'en' ? rabbitEng : rabbitTc;
      animalMobileImage = language === 'en' ? rabbitEngMobile : rabbitTcMobile;
    } else if (stress >= 2.1 && stress <= 2.5) {
      animalImage = language === 'en' ? bullEng : bullTc;
      animalMobileImage = language === 'en' ? bullEngMobile : bullTcMobile;
    } else if (stress >= 2.6 && stress <= 5) {
      animalImage = language === 'en' ? lionEng : lionTc;
      animalMobileImage = language === 'en' ? lionEngMobile : lionTcMobile;
    }

    return isMobile ? animalMobileImage : animalImage;
  }

  return (
    <UserValidator disabled={!displayButtons}>
      <>

      <div className="container-fluid h-100 overflow-scroll">
      <LanguagePicker />
        <div className="resultContainer d-flex flex-column">
          <div ref={result}>
            <div className="row w-100 m-0 p-0">
              <div className="col image-container d-flex justify-content-center animalImageContainer">
                <img src={getAnimalImage(false, language)} alt="result-animal" className="animal-result position-relative mx-auto d-none d-sm-block" />
                <img src={getAnimalImage(true, language)} alt="result-animal" className="animal-result-mobile mx-auto d-block d-sm-none" />
              </div>
            </div>

            <div className="row">
              <div className="col w-100">
                <div className="row d-flex justify-content-center vitalSignsContainer">
                  {holistic.generalWellness &&
                    <VitalSign
                      name="general-wellness"
                      value={holistic.generalWellness}
                      label={strings.RESULTS_PAGE_VITALS.HEALTH_SCORE}
                      icon=""
                    />}
                    {/* <VitalSign name="General Wellness" value={holistic.generalWellness} label={strings.RESULTS_PAGE_VITALS.HEALTH_SCORE}  /> */}

                  {vs.heartRate &&
                    <VitalSign
                      name="heart-rate"
                      value={vs.heartRate}
                      label={strings.RESULTS_PAGE_VITALS.HEART_RATE}
                      units="bpm"
                      icon={variationIcon}
                    />}

                  {vs.stress &&
                    <VitalSign
                      name="stress"
                      value={vs.stress}
                      label={strings.RESULTS_PAGE_VITALS.STRESS_LEVEL}
                      units=""
                      stress={vs.stress} // stress={getStressRating()}
                      icon=""
                    /> }

                  {vs.bloodPressure &&
                    <VitalSign
                      name="blood-pressure"
                      value={vs.bloodPressure}
                      label={strings.RESULTS_PAGE_VITALS.BLOOD_PRESSURE}
                      units="mmhg"
                      icon={bloodPressureIcon}
                    />}

                  {vs.hrvRmssd &&
                    <VitalSign
                      name="heart-rate-variability"
                      value={vs.hrvRmssd}
                      label={strings.RESULTS_PAGE_VITALS.HEART_RATE_VARIABILITY}
                      units="ms"
                      icon={variationIcon}
                    />}

                  <div className="d-none d-sm-block"></div>

                  {vs.respiratoryRate &&
                    <VitalSign
                      name="respiratory-rate"
                      value={vs.respiratoryRate}
                      label={strings.RESULTS_PAGE_VITALS.RESPIRATORY_RATE}
                      units="bpm"
                      icon={variationIcon}
                    />}

                  {vs.spo2 &&
                    <VitalSign
                      name="oxygen"
                      label={strings.RESULTS_PAGE_VITALS.OXYGEN_LEVEL}
                      value={vs.spo2}
                      icon=""
                    /> }
                    {/* <VitalSign name="SPO2" label={strings.RESULTS_PAGE_VITALS.OXYGEN_LEVEL} value={vs.spo2}  />  */}

                  <div className="bufferSquare square d-block d-sm-none col-6 col-md-2 mx-md-2 p-0 opacity-0"> </div>

                </div>
              </div>
            </div>
          </div>

           {displayButtons && (
           <div className='text-center'>
             {copiedPopupShow && (<div className="results-page-popup mx-auto text-white m-0">{strings.RESULTS_PAGE_POPUP}</div>)}
              <button className="button mb-2 mx-1" onClick={ ()=> back()}>
                <img src={resetButtonIcon} alt="retry-button" />
                <span className="mx-2">{strings.RESULTS_PAGE_BUTTONS.RETRY}</span>
              </button>

              <button className="button mb-2 mx-1" onClick={ ()=> save()}>
                <img src={saveButtonIcon} alt="save-button" />
                <span className="mx-2">{strings.RESULTS_PAGE_BUTTONS.SAVE}</span>
              </button>

              <button className="button mb-2 mx-1" onClick={ ()=> share()}>
                <img src={shareButtonIcon} alt="share-button" />
                <span className="mx-2">{strings.RESULTS_PAGE_BUTTONS.SHARE}</span>
              </button>

              <div>
                { showLuckyDrawButton &&  
                <button className="lucky-draw-button button mt-0 mx-auto"  onClick={ ()=> luckyDraw()}>
                  <span className="mx-2">{strings.RESULTS_PAGE_BUTTONS.LUCKY_DRAW}</span>
                </button> 
                } 
              </div>
            </div>
           )}
        </div>
      </div>
      </>
    </UserValidator>
  );
}

export default HealthResult;
