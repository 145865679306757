import { ServerId } from 'react-vital-sign-camera'

interface Config {
  apiKey: string
  userId: string
  serverId: ServerId
  s3Bucket: string
  shareExpirationHour: number
  defaultDevUserId: string
}

export const CONFIG:Config = {
  apiKey: 'CBAaQvrd8i3EAlKdKWrnI5hhLcuwEsfIZ7MzMHaa',
  userId: '8d5c7dc2-d8f8-4f2d-9c6a-e494b8c02a89',
  serverId: ServerId.AwsProdEnterprise,
  s3Bucket: 'fwd-data-bucket',
  shareExpirationHour: 24*3, // 3 days
  defaultDevUserId: '1234'
}
