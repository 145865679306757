import { useCallback, useEffect, useState, useMemo } from 'react'
import { useUserContext } from '../user/UserProvider'
import { writeToS3Bucket, readFromS3Bucket } from '../s3bucket/S3Bucket'
import { useSearchParams } from 'react-router-dom';

import { CONFIG } from '../config/Config'
import { useLocale } from '../locale/LocaleProvider';

function useCreateShareURL() {

  const userContext = useUserContext()
  const { locale } = useLocale()
  const { strings } = locale

  return useCallback( async () => {

    if (!userContext.user) {
      console.error('No user context')
      return
    }
    const timestamp = Number(new Date().valueOf())
    const userId = userContext.user.userId
    const shareId = crypto.randomUUID()
    await writeToS3Bucket(`share/${shareId}.json`, { userId, timestamp })

    return `${window.location.protocol}//${window.location.host}/#/?shareId=${shareId}&language=${strings.LANGUAGE_CODE}`

  }, [userContext.user, strings.LANGUAGE_CODE])
}

async function shareURL(url:string):Promise<'shared'|'copied'|'error'> {

    if (navigator.canShare && navigator.canShare({url})) {
      await navigator.share({url})
      console.log('Link shared: ', url)
      return 'shared'
    }

    try {
      await navigator.clipboard.writeText(url)
      console.log('Link copied: ', url)
      return 'copied'
    } catch (error) {
      console.log("error with copying to clipboard: ", error);
      return 'error'
    }
}

export interface SharingInfo {
  sharedBy: string,
  timestamp: number
}

export function useValidateShareId() {

  const [searchParams] = useSearchParams()

  return useCallback (async () => {

    if (!searchParams.has('shareId')) {
      console.info('Missing shareId param.')
      return undefined
    }
    const shareId = searchParams.get('shareId')
    const json = await readFromS3Bucket(`share/${shareId}.json`)

    if (!json || (typeof json !== 'object')) {
      console.info('Invalid share object:', json)
      return undefined
    }

    const { userId, timestamp }  = json 
    if (typeof userId !== 'string') {
      console.info('Invalid user Id:', userId)
      return undefined
    }
    if (typeof timestamp !== 'number') {
      console.info('Invalid share timestamp.')
      return undefined
    }

    const elapsed = new Date().getTime() - new Date(timestamp).getTime()
    if (elapsed > CONFIG.shareExpirationHour * 60 * 60 * 1000) {
      console.info('Share Id expired.')
      return undefined
    }
    
    return {
      sharedBy: userId as string, 
      timestamp: timestamp as number
    } as SharingInfo

  }, [])

}

export function useSharingInfo(): SharingInfo|undefined {

  const validateShareId = useValidateShareId()

  const [sharingInfo, setSharingInfo] = useState<SharingInfo|undefined>()
  useEffect( () => {
    validateShareId().then( setSharingInfo )
  }, [validateShareId] )

  return sharingInfo

}

export function useUserFromSharingInfo() {

  const userCtx = useUserContext()
  const sharingInfo = useSharingInfo()

  return useMemo(()=> {
    if (!sharingInfo) {
      return false
    }

    const userId = `${sharingInfo.sharedBy}-T${sharingInfo.timestamp}`
    userCtx.dispatch({type: 'NEW_USER', payload:{ userId }})
    return true

  }, [sharingInfo, userCtx])


}

export function useShareController() {

  const createShareURL = useCreateShareURL();
  const [copiedPopupShow, setCopiedPopupShow] = useState(false);

  const copyURL = async (url:string) => {
    if (url === undefined) { return }
    const status = await shareURL(url)
    switch(status) {
      case 'shared':
        break
      case 'copied':
        if(!copiedPopupShow) {
          setCopiedPopupShow(true);
        }
        setTimeout(() => {
          setCopiedPopupShow(false);
        }, 3000)
      break
      case 'error':
        break
    }
  }

  const share = async () => {
    const url = await createShareURL()
    if (url === undefined) { return }
    return copyURL(url)
  }

  return {copiedPopupShow, share}

}