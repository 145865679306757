import { useNavigate } from 'react-router-dom';
import { useLocale } from '../../common/locale/LocaleProvider';
import LanguagePicker from '../../common/components/LanguagePicker';

import "bootstrap/dist/css/bootstrap.min.css";
import './Submitted.css';

import balloons from './assets/balloons.svg';
import shareButtonIcon from "./assets/share.svg";
import shareButtonIconWhite from './assets/share-white.svg';
import { useShareController } from '../../common/share/Share';
import { useRef } from 'react';

export default function Submitted() {
  const { locale } = useLocale();
  const { strings } = locale;
  const navigate = useNavigate();
  const {copiedPopupShow, share} = useShareController();
  const shareIcon = useRef<HTMLImageElement>(null);

  return (
    <>
      <div className="container-fluid h-100 p-0">
        <LanguagePicker luckyDraw={true}/>
          <div className="top-bar w-100"></div>
            <div className="lucky-draw-submitted-page h-100">
              <div className="row h-100">
                <div className="col-1 col-md-2 col-md-2 p-0"></div>
                  <div className="col-10 col-md-8 text-center d-flex justify-content-center flex-column text-center px-3" >
                      <div className="lucky-draw-content mx-auto">
                        <p className="lucky-draw-submitted-title text-center mt-3 pt-5 mb-0 position-absolute top-0 start-50 translate-middle">{strings.LUCKY_DRAW_TITLE}</p>
                        <img src={balloons} alt="balloons" className="lucky-draw-submitted-icon"></img>
                        <p className="lucky-draw-submitted-message mx-auto mt-4">{strings.LUCKY_DRAW_SUBMITTED_PAGE_MESSAGE} </p>
                        <div>
                          <button className="lucky-draw-submitted-button lucky-draw-submitted-share-button mx-auto" 
                            onClick={ ()=> share() }>
                            <span className="mx-2">{strings.LUCKY_DRAW_SUBMITTED_PAGE_BUTTONS.SHARE}</span>
                          </button>
                        </div>
                        <div>
                          <button className="lucky-draw-submitted-button lucky-draw-submitted-back-button mx-auto" onClick={()=>navigate("/")}>{strings.LUCKY_DRAW_SUBMITTED_PAGE_BUTTON}</button>
                        </div>
                        {copiedPopupShow && (<div className="lucky-draw-submitted-page-popup mx-auto text-white m-0">{strings.RESULTS_PAGE_POPUP}</div>)}
                      </div>
                  </div>
                <div className="col-1 col-md-2 p-0"></div>
              </div>
            </div>
      </div>
    </>
  )
}
