import React, { useReducer } from 'react';
import { LOCALE_STRINGS, LocaleStrings, LanguageCode } from './LocaleStrings';

const initialState = {
  strings: LOCALE_STRINGS['en']
}

const reducer = (state: { strings: LocaleStrings }, action: { type: string, payload: LanguageCode }) => {
  switch (action.type) {
    case 'CHANGE_LOCALE': {
      if (state.strings.LANGUAGE_CODE === action.payload) {
        return state
      }
      return { ...state, strings: LOCALE_STRINGS[action.payload] }
    }
    default:
      return state
  }
}

const LocaleContext = React.createContext({ locale: initialState, changeLocale: (lang: LanguageCode) => { } });

export function LocaleProvider(props: { children: React.ReactElement<any, string | React.JSXElementConstructor<any>> }) {

  const [locale, dispatch] = useReducer(reducer, initialState)

  const changeLocale = (lang: LanguageCode) => {
    dispatch({ type: 'CHANGE_LOCALE', payload: lang })
  }

  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      {props.children}
    </LocaleContext.Provider>
  )
}


export const useLocale = () => React.useContext(LocaleContext)

export * from './LocaleStrings'