import { useSearchParams } from 'react-router-dom';
import { useLocale, ALL_LANGUAGE_CODES, LanguageCode } from './LocaleProvider';
import { useEffect } from 'react';


export const useLanguageFromParam = () => {

  const { locale, changeLocale } = useLocale()
  const [searchParams] = useSearchParams()

  let lang = searchParams.get('language')

  useEffect(() => {
    if (lang && locale.strings.LANGUAGE_CODE !== lang &&
        ALL_LANGUAGE_CODES.includes(lang as LanguageCode)) {
          changeLocale(lang as LanguageCode)
    }
  }, [changeLocale, lang])

}

